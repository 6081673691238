import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/index";
import Strings from "../../res/String";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Actions";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handlerLogout = () => {
    dispatch(logout());

    localStorage.removeItem("formData");
    localStorage.removeItem("currentStep");
    navigate("/");
  };
  const values = useSelector((state) => state.loginData);
  return (
    <>
      <div className="d-flex flex-column flex-shrink-0  sidebar_head">
        <a
          href="/"
          className="d-flex m-3 align-items-center  text-decoration-none sidebar_logo"
        >
          <img src={Images.logo} alt="Best Timbers" style={{ width: "100%" }} />
        </a>
        <hr />
        {/* <ul className="nav nav-pills flex-column mb-auto">
          <li className={`nav-items ${location.pathname == '/' ? "nav_active" : ""}`}>
            <NavLink to="/" className={`nav-label`} aria-current="page">
              {Strings.data_management}
            </NavLink>
          </li>
          <li className={`nav-items ${location.pathname == '/dataEntryForm' ? "nav_active" : ""}`}>
            <NavLink to="/dataEntryForm" className="nav-label">
              {Strings.data_entry}
            </NavLink>
          </li>
          <li className={`nav-items ${location.pathname == '/agentManagement' ? "nav_active" : ""}`}>
            <NavLink to="/agentManagement" className="nav-label">
              {Strings.agentManagement}
            </NavLink>
          </li>
          <li className={`nav-items ${location.pathname == '/newPackingList' ? "nav_active" : ""}`}>
            <NavLink to="/newPackingList" className="nav-label">
              {Strings.newParkingList}
            </NavLink>
          </li>
          <li className={`nav-items ${location.pathname == '/forwardCover' ? "nav_active" : ""}`}>
            <NavLink to="/forwardCover" className="nav-label">
              {Strings.forwardcover}
            </NavLink>
          </li>
          <li className={`nav-items ${location.pathname == '/userManagement' ? "nav_active" : ""}`}>
            <NavLink to="/userManagement" className="nav-label">
              {Strings.user_management}
            </NavLink>
          </li>
          <li className={`nav-items ${location.pathname == '/addUser' ? "nav_active" : ""}`}>
            <NavLink to="/addUser" className="nav-label">
              {Strings.new_user}
            </NavLink>
          </li>
          <li className={`nav-items`} onClick={() => handlerLogout()}>
            <div className="nav-label" onClick={() => handlerLogout()}>
              {Strings.logout}
            </div>
          </li>
        </ul> */}
        {(values?.role === "superAdmin" || values?.role === "admin") && (
          <>
            <ul className="nav nav-pills flex-column mb-auto">
              <li
                className={`nav-items ${
                  location.pathname == "/" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/" className={`nav-label`} aria-current="page">
                  {Strings.data_management}
                </NavLink>
              </li>
              {/* <li
                className={`nav-items ${
                  location.pathname == "/dataEntryForm" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/dataEntryForm" className="nav-label">
                  {Strings.data_entry}
                </NavLink>
              </li> */}
              <li
                className={`nav-items ${
                  location.pathname == "/agentManagement" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/agentManagement" className="nav-label">
                  {Strings.agentManagement}
                </NavLink>
              </li>
              <li
                className={`nav-items ${
                  location.pathname == "/newPackingList" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/newPackingList" className="nav-label">
                  {Strings.newParkingList}
                </NavLink>
              </li>
              <li
                className={`nav-items ${
                  location.pathname == "/forwardCover" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/forwardCover" className="nav-label">
                  {Strings.forwardcover}
                </NavLink>
              </li>
              <li
                className={`nav-items ${
                  location.pathname == "/userManagement" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/userManagement" className="nav-label">
                  {Strings.user_management}
                </NavLink>
              </li>
              <li
                className={`nav-items ${
                  location.pathname == "/addUser" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/addUser" className="nav-label">
                  {Strings.new_user}
                </NavLink>
              </li>
              <li className={`nav-items`} onClick={() => handlerLogout()}>
                <div className="nav-label" onClick={() => handlerLogout()}>
                  {Strings.logout}
                </div>
              </li>
            </ul>
          </>
        )}
        {values?.role == "limitedAdmin" && (
          <>
            <ul className="nav nav-pills flex-column mb-auto">
              <li
                className={`nav-items ${
                  location.pathname == "/" ? "nav_active" : ""
                }`}
              >
                <NavLink to="/" className="nav-label">
                  PKL MANAGEMENT
                </NavLink>
              </li>

              <li className={`nav-items`} onClick={handlerLogout}>
                <div className="nav-label">{Strings.logout}</div>
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
}
