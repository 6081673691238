import { LOGIN_SUCCESS, FORMDATA_DETAILS, LOGOUT, COPYDOC_DETAILS, PL, PLSTATUS, PLSTAPLSTATUSDISACTIVETUS, LOGIN_DATA } from "./ActionTypes";

const initialState = {
    login: false,
    loginData:{},
    formdata: {
    },
    copydoc: {},
    pl: false,
    plstatus: false
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: true,
            };
        case LOGOUT:
            return { ...initialState };
        case PL:
            return {
                ...state,
                pl: !state?.pl,
            };
        case LOGIN_DATA:
            return {
                ...state,
                loginData: action.payload,
            };
        case PLSTATUS:
            return {
                ...state,
                plstatus: true,
            };
        case PLSTAPLSTATUSDISACTIVETUS:
            return {
                ...state,
                plstatus: false,
            };

        case FORMDATA_DETAILS:
            return {
                ...state,
                formdata: action.payload,
            };
        case COPYDOC_DETAILS:
            return {
                ...state,
                copydoc: action.payload,
            };


        default:
            return state;
    }
};

export default Reducer;
