import React, { useEffect, useMemo, useState } from "react";
import html2pdf from "html2pdf.js";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets";

// Function to chunk data
const chunkData = (data, chunkSize) => {
  const result = [];
  for (let i = 0; i < data.length; i += chunkSize) {
    result.push(data.slice(i, i + chunkSize));
  }
  return result;
};

const SwanDownload = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  console.log("STATE", state);

  const tableData = Array(200).fill({
    width: "23",
    thickness: "23",
    length: "23",
    noOfPieces: "2",
    cbm: "23.23",
  });

  const chunkedData = useMemo(() => {
    return chunkData((state && state?.newTeakSwan) || [], 50);
  }, [state]);

  const groupedChunks = useMemo(() => {
    const result = [];
    for (let i = 0; i < chunkedData.length; i += 3) {
      result.push(chunkedData.slice(i, i + 3));
    }
    return result;
  }, [chunkedData]);

  const [summaryPageBreak, setSummaryPageBreak] = useState(false);

  useEffect(() => {
    const checkPageBreak = () => {
      const container = document.getElementById("pdf-content");
      const summary = document.getElementById("summary-section");

      if (container && summary) {
        const containerHeight = container.offsetHeight;
        const summaryTop = summary.offsetTop;
        setSummaryPageBreak(summaryTop > containerHeight - 150);
      }
    };

    setTimeout(checkPageBreak, 500);
  }, []);

  const downloadPDF = () => {
    const element = document.getElementById("pdf-content");

    if (!element) {
      console.error("PDF content element not found!");
      return;
    }

    const options = {
      margin: 5,
      filename: `PKL - ${state && state?.pkl}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: "mm", format: [220, 385], orientation: "portrait" },
    };

    html2pdf()
      .set(options)
      .from(element)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        // console.log(`Total pages: ${totalPages}`);

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0);

          pdf.text(`Page ${i} `, pdf.internal.pageSize.getWidth() / 2, 7, {
            align: "center",
          });
        }
      })
      .save();
  };

  const Header = () => (
    <div className="header-section" style={{ marginBottom: "0px" }}>
      <div className="row p-0 m-0">
        <div className="col-6">
          <div className="row">
            <div className="col-12 pkl-download-from-to-address-head p-0 m-0">
              From
            </div>
          </div>
          <div className="row p-0 m-0">
            <div className="col-1"></div>
            <div className="col-11">
              <h6 className="pkl-download-heading">BEST TIMBERS</h6>
              <p className="pkl-download-from-address">
                New.NO.515, Old.No. 274,
              </p>
              <p className="pkl-download-from-address">Sydenhams Road,</p>
              <p className="pkl-download-from-address">
                Nehru Timber Market, Choolai
              </p>
              <p className="pkl-download-from-address">Chennai - 600112</p>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div
              className="col-8 pkl-download-from-address text-end p-0 m-0"
              style={{ fontStyle: "normal" }}
            >
              <b>DATE : {state && state?.date}</b>
            </div>
          </div>
          <div className="row p-0 m-0">
            <div className="col-2 pkl-download-from-to-address-head">To</div>

            <div className="col-10 px-4">
              <p className="pkl-download-address py-1">
                {(state && state?.toName?.toUpperCase()) || ""}
              </p>
              <p className="pkl-download-address py-1">
                {`${(state && state?.toMobileNumber) || ""}${
                  (state && state?.toStreet?.toUpperCase()) || ""
                }`}
                ,
              </p>
              <p className="pkl-download-address py-1">
                {`${(state && state?.toLandmark?.toUpperCase()) || ""}, ${
                  (state && state?.toCity?.toUpperCase()) || ""
                }, `}
              </p>
              <p className="pkl-download-address py-1">
                {`${(state && state?.toDistrict?.toUpperCase()) || ""}-${
                  (state && state?.toPincode) || ""
                } `}
              </p>
              <p className="pkl-download-address py-1">
                GST NO : {(state && state?.gstNumber) || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-0 m-0">
        <div className="col-4 p-0 mt-1 mb-0">
          <i style={{ fontSize: "15" }}>Item : </i>
          <span
            style={{
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: "17px",
            }}
            className="pkl-download-from-address m-0"
          >
            TEAK ROUND SIZES
          </span>
        </div>
        <div className="col-4 p-0 mt-1 mb-0 text-center">
          <h6
            style={{
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: "17px",
            }}
            className="pkl-download-from-address"
          >
            PKL-{state && state?.pkl}
          </h6>
        </div>
        <div className="col-4 p-0 mt-1 mb-0">
          <i style={{ fontSize: "15px" }}>Lorry No : </i>
          <span
            style={{
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: "17px",
            }}
            className="pkl-download-from-address"
          >
            {state && state?.lorry}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <button
        onClick={downloadPDF}
        style={{ position: "absolute", right: "10px", top: "30px" }}
        className="btn btn-success"
      >
        Download PDF
      </button>

      <div
        style={{
          width: "45px",
          height: "45px",
          cursor: "pointer",
          position: "absolute",
          top: "8px",
          left: "40px",
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={Images?.backIcon} style={{ width: "100%", height: "100%" }} />
      </div>

      <div
        className="container"
        id="pdf-content"
        style={{ padding: "10px", overflow: "auto" }}
      >
        {groupedChunks &&
          groupedChunks?.map((tableGroup, groupIndex) => (
            <div
              key={groupIndex}
              style={{ pageBreakBefore: groupIndex > 0 ? "always" : "auto" }}
            >
              <Header />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {tableGroup &&
                  tableGroup?.map((chunk, tableIndex) => {
                    const startIndex = (groupIndex * 3 + tableIndex) * 50 + 1;
                    const totalCBM = chunk
                      .reduce((total, item) => total + parseFloat(item?.cbm), 0)
                      .toFixed(3); // Calculate total CBM for the chunk

                    return (
                      <div
                        key={tableIndex}
                        style={{
                          width: "32%",
                          marginBottom: "10px",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            border: "1px solid black",
                          }}
                        >
                          <thead>
                            <tr>
                              <th className="pkl-download-table-head">S.NO</th>
                              <th className="pkl-download-table-head">WIDTH</th>
                              <th className="pkl-download-table-head">
                                THICKNS
                              </th>
                              <th className="pkl-download-table-head">
                                LENGTH
                              </th>
                              <th className="pkl-download-table-head">PCS</th>
                              <th className="pkl-download-table-head">CBM</th>
                            </tr>
                          </thead>
                          <tbody>
                            {chunk?.map((data, index) => (
                              <tr key={index}>
                                <td className="pkl-download-table-data">
                                  {startIndex + index}
                                </td>
                                <td className="pkl-download-table-data">
                                  {data?.width}
                                </td>
                                <td className="pkl-download-table-data">
                                  {data?.thickness}
                                </td>
                                <td className="pkl-download-table-data">
                                  {data?.length}
                                </td>
                                <td className="pkl-download-table-data">
                                  {data?.noOfPieces}
                                </td>
                                <td className="pkl-download-table-data">
                                  {data?.cbm}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td
                                colSpan="5"
                                className="pkl-download-table-data"
                              >
                                Total
                              </td>
                              <td className="pkl-download-table-data">
                                {totalCBM} {/* Display the total CBM */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}

        <div
          id="summary-section"
          style={{ pageBreakBefore: summaryPageBreak ? "always" : "auto" }}
        >
          {summaryPageBreak && <Header />}
          <table className="my-3">
            <thead>
              <tr>
                <th colSpan="2" style={{ padding: "2px" }}>
                  SUMMARY DETAILS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: "2px",
                    fontSize: "14px",
                  }}
                >
                  <b>Total Nos</b>
                </td>
                <td
                  style={{
                    padding: "2px",
                    fontSize: "14px",
                  }}
                >
                  {state?.totalTeakNos}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "2px",
                    fontSize: "14px",
                  }}
                >
                  <b>Total Cbm</b>
                </td>
                <td
                  style={{
                    padding: "2px",
                    fontSize: "14px",
                  }}
                >
                  {state?.totalTeakCbm}
                </td>
              </tr>
            </tbody>
          </table>

          {/* New table for aggregated sizes */}
          {state?.aggregatedSizes?.length > 0 && (
            <table className="my-2">
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Sizes
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Nos
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      fontSize: "12px",
                    }}
                  >
                    CBM
                  </th>
                </tr>
              </thead>
              <tbody>
                {state?.aggregatedSizes?.map((data, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {data?.sizes}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {data?.nos}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {data?.cbm.toFixed(3)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default SwanDownload;
