import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Images from "../../assets";

const ACDownload = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const printRef = useRef();

  const handleDownload = () => {
    const input = printRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`AC - ${state && state?.pkl}.pdf`);
    });
  };

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "45px",
          height: "45px",
          cursor: "pointer",
          position: "absolute",
          top: "8px",
          left: "40px",
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={Images?.backIcon} style={{ width: "100%", height: "100%" }} />
      </div>
      <button
        onClick={handleDownload}
        className="btn btn-primary"
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          position: "absolute",
          top: "20px",
          right: "20px",
        }}
      >
        Download
      </button>

      <div ref={printRef} style={{ backgroundColor: "white", padding: "20px" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            fontFamily: "Calibri",
            fontSize: "14px",
          }}
        >
          <thead>
            <tr>
              <th
                colSpan="8"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  backgroundColor: "#f2f2f2",
                }}
              >
                ACCOUNT DETAILS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={cellHeadStyle}>PKL</td>
              <td style={cellStyle}>{state?.pkl || "-"}</td>
              <td style={cellHeadStyle}>PARTY/AGENT</td>
              <td style={cellStyle}>
                {`${state?.partyName || "-"} / ${state?.agentName || "-"}`}
              </td>
              <td style={cellHeadStyle}>LORRY</td>
              <td style={cellStyle}>{state?.lorry || "-"}</td>
            </tr>
            <tr>
              <td style={cellHeadStyle}>SHIP TO</td>
              <td colSpan="7" style={cellStyle}>
                {state?.shipTo || "-"}
              </td>
            </tr>
            <tr>
              <td style={cellHeadStyle}>CBM</td>
              <td style={cellStyle}>{state?.invCBM || "-"}</td>
              <td style={cellHeadStyle}>RATE</td>
              <td style={cellStyle}>{state?.rate || "-"}</td>
              <td style={cellHeadStyle}>AMT</td>
              <td style={cellStyle}>{state?.amount.toFixed(2) || "-"}</td>
            </tr>
            <tr>
              <td style={cellHeadStyle}>MTS</td>
              <td colSpan="7" style={cellStyle}>
                {state?.sumMTS || "-"}
              </td>
            </tr>
            <tr>
              <td style={cellHeadStyle}>DISCOUNT</td>
              <td colSpan="7" style={cellStyle}>
                {state?.loadvalue || "-"}
              </td>
            </tr>
            <tr>
              <td style={cellHeadStyle}>INV CBM</td>
              <td style={cellStyle}>{state?.invCBM || "-"}</td>
              <td style={cellHeadStyle}>INV RATE</td>
              <td style={cellStyle}>{state?.invRate || "-"}</td>
              <td style={cellHeadStyle}>INV AMT</td>
              <td style={cellStyle}>{state?.invAmount || "-"}</td>
            </tr>
            <tr>
              <td style={cellHeadStyle}>AVG CFT</td>
              <td style={cellStyle}>{state?.avgCft || "-"}</td>
              <td style={cellHeadStyle}>NOS</td>
              <td style={cellStyle}>
                {state?.totalNos || state?.totalTeakNos || "-"}
              </td>
              <td style={cellHeadStyle}>PLUSGST</td>
              <td style={cellStyle}>-</td>
            </tr>
            <tr>
              <td style={cellHeadStyle}>DIST</td>
              <td style={cellStyle}>{state?.distance || "-"}</td>
              <td style={cellHeadStyle}>PIN-PN</td>
              <td style={cellStyle}>{state?.toPincode || "-"}</td>
              <td style={cellStyle}>{state?.pincode || "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

// Cell Style for Table
const cellStyle = {
  border: "1px solid black",
  padding: "5px",
  textAlign: "center",
  minWidth: "100px",
};
const cellHeadStyle = {
  border: "1px solid black",
  padding: "5px",
  textAlign: "center",
  minWidth: "100px",
  fontWeight: "900",
  backgroundColor: "#A5D6A7",
};

export default ACDownload;
