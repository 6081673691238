import React, { useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";

import FormHeader from "../../common/components/FormHeader/FormHeader";
import DataForm from "../../common/components/dataEntryFormComponent/DataForm";
import Strings from "../../common/res/String";
import { useLocation } from "react-router-dom";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import DataEntryEditForm from "../../common/components/dataEntryFormComponent/DataEntryEditForm";
import FormPage from "../../common/components/dataEntryFormComponent/FormPage";
import NewDataForm from "../../common/components/dataEntryFormComponent/NewDataForm";
import PopUpModal from "../../common/components/popupmodal/PopUpModal";
// import logo from "../../assets/img/timber_logo.png";
import logo from "../../common/assets/img/timber_logo.png"
import { useDispatch, useSelector } from "react-redux";
import { pl } from "../../common/Redux/Actions";
import PLPassword from "../../common/components/plPassword/PLPassword";
export default function DataEntryForm() {
  const { state } = useLocation();
  const [isCount, setIsCount] = useState(1);
  const [showFileName , setShowFileName] = useState(1234567898)
    console.log("showFileName", showFileName);
  const dispatch = useDispatch()
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>
          {state !== null ? (
            <>
              <div className="col-sm-10 col-lg-10">
                <div className="row align-items-center">
                  <div className="headerTimeLine px-5">
                    <TimeLine currentStep={isCount} />
                  </div>
                </div>
                <div className="row" style={{ paddingLeft: "3rem" }}>
                  <div
                    className="col-lg-9"
                    style={{
                      paddingTop: "4px",
                      // height: "calc(100vh - 20px)",
                      // overflow: "scroll",
                      // paddingBottom: "3rem",
                    }}
                  >
                    <div className="dataEntryMainContainer --copydocument">
                      <FormHeader header={Strings.data_entry} showFileName={showFileName} />
                      <div className="data-entry-form-block">
                        {/* <DataForm /> */}
                        <NewDataForm showFileName={showFileName} setShowFileName={setShowFileName} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 p-0 right_col">
                    <div className="headerTimeLine_left">
                      <RightSidebar
                        setIsCount={setIsCount}
                        islabel="COPY DOCUMENT"
                        setShowPasswordPrompt={setShowPasswordPrompt}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-sm-10 col-lg-8 mt-3 mb-4 offset-1 d-flex align-items-center p-0">
                <div className="dataEntryMainContainer">
                  <FormHeader header={Strings.data_entry} showFileName={showFileName} />
                  <div className="data-entry-form-block">
                    <NewDataForm  showFileName={showFileName} setShowFileName={setShowFileName} />
                  </div>
                </div>
              </div>
            </>
          )}

        </div>
      </div>
      <PLPassword />
    </>
  );
}
