import React, { useRef } from 'react'
import NewPackingList from '../../common/components/newPakingList/NewPackingList'
import FormHeader from '../../common/components/FormHeader/FormHeader'
import Strings from '../../common/res/String'
import Sidebar from '../../common/components/Sidebar/sidebar'

export default function NewPackingListScreen() {
  const scrollRef = useRef(null);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 p-0">
            <Sidebar />
          </div>
          <div className="col-8 p-0">
            <div className="addUserMainContainer my-4" ref={scrollRef}>
              <FormHeader header={Strings.parkingListManagement} />
              <div className="add-user-form-block">
                <NewPackingList  divref={scrollRef} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
