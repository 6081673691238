import React from "react";

export default function FormHeader({ header, showFileName }) {
  // console.log('showFileName',showFileName);

  return (
    <div className="data-entry-head d-flex justify-content-between align-items-center">
      <h6 className="data-entry-title">{header}</h6>
      {showFileName && (
        <p className="data-entry-title mb-0">{showFileName}</p>
      )}
    </div>
  );
}
