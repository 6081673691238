import React from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";

import AgentManagementCom from "../../common/components/agentManagement/AgentManagementCom";

const AgentManagement = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>
          <div className="col-sm-10 col-lg-10">
            <div className="row">
              <div className="viewUserMainContainer p-0">
                <AgentManagementCom />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentManagement;
