import React, { useEffect, useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import PLForm from "../../common/components/PLFormComponent/PLForm";
import FileDocument from "../../common/components/FileDocument/FileDocument";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import PLPassword from "../../common/components/plPassword/PLPassword";
import { useSelector } from "react-redux";
import Images from "../../common/assets";
import { jsPDF } from "jspdf";
import { useMutation } from "react-query";

import axios from "axios";

const base64ToPdfUrl = (base64String) => {
  if (!base64String) {
    console.error("Base64 string is undefined or empty");
    return null;
  }
  console.log("base64String_CCC", base64String);

  try {
    // Decode base64 string
    const byteCharacters = atob(base64String);

    // Convert to byte array
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob and URL
    const blob = new Blob([byteArray], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error processing base64 string:", error);
    return null;
  }
};

const FileDocumentPage = () => {
  const [loading, setLoading] = useState(false);
  const retrievedData = useSelector((state) => state?.formdata);
  console.log(
    "formData-->",
    retrievedData &&
      retrievedData?.userForm?.map((data, index) => {
        return data;
      })
  );
  let array = [
    retrievedData && retrievedData?.advicedocument,
    retrievedData && retrievedData?.bldocument,
    retrievedData && retrievedData?.boedocument,
    retrievedData && retrievedData?.codocument,
    retrievedData && retrievedData?.invoicedocument,
    retrievedData && retrievedData?.phytodocument,
    retrievedData && retrievedData?.quotedocument,
    retrievedData && retrievedData?.sblcDocument,
    retrievedData && retrievedData?.shippingbilldocument,
    retrievedData && retrievedData?.swiftintdocument,
    // retrievedData?.userForm
    //  val.containerImage}),
  ];
  let formImg = retrievedData?.userForm;
  const [isCount, setIsCount] = useState(10);
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    const url = base64ToPdfUrl(array);
    setPdfUrl(url);
  }, []);
  console.log("pdfUrl", pdfUrl, array);
  let data = [
    {
      label: "FILE_NAME.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME2.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME3.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME4.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME5.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME6.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME7.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME8.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME9.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME10.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME11.FILE_TYPE",
      files: pdfUrl,
    },
    {
      label: "FILE_NAME12.FILE_TYPE",
      files: pdfUrl,
    },
  ];
  const navigate = useNavigate();
  console.log("retrived data ", retrievedData);
  // console.log("array.length", formImg);

  const downloadFormImg = formImg.map((val) => {
    return val.containerImage;
  });
  const downloadFormNo = formImg.map((val) => {
    return val.containerNo;
  });

  // console.log("downloadFormImg", downloadFormImg);

  // console.log(
  //   "retrievedData?.userForm",
  //   retrievedData?.userForm[0]?.containerNo
  // );
  console.log("RETRIVED DATA", retrievedData);

  let label = [
    "ADVICE DOCUMENT",
    "BL DOCUMENT",
    "BOE DOCUMENT",
    "CO DOCUMENT",
    "INVOICE DOCUMENT",
    "PHYTO DOCUMENT",
    "QUOTE DOCUMENT",
    "SBLC DOCUMENT",
    "SHIPPING BILL DOCUMENT",

    "SWIFT INT DOCUMENT",

    // "SHIPPING BILL DOCUMENT",
    // "CONTAINER IMAGE"
    // retrievedData && retrievedData?.userForm?.containerNo
    retrievedData &&
      retrievedData?.userForm?.map((val) => {
        return val?.avgCftAvgGirth;
      }),
  ];

  const base64ToBlob = (base64String, contentType) => {
    const sliceSize = 512;
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters?.length; offset += sliceSize) {
      const slice = byteCharacters?.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const downloadZipWithPdfs = async (
    base64Pdfs,
    downloadFormImg,
    // downloadFormNo,
    fileNames
  ) => {
    console.log("functionImage", downloadFormImg);

    const zip = new JSZip();
    setLoading(true);

    downloadFormImg?.forEach((imageArray, index) => {
      console.log("forEachData", imageArray);

      imageArray.forEach((image, imageIndex) => {
        const imageBlob = base64ToBlob(image, "application/jpeg");
        zip.file(`Container ${index + 1}/${imageIndex + 1}.jpeg`, imageBlob);
      });

      // const pdfBlob = base64ToBlob(data, "application/jpeg");
      // const folderName = "image s"; // You can change this to your desired folder name
      // zip.file(`${folderName}/${index}.jpeg`,  pdfBlob);
    });

    base64Pdfs.forEach((pdfBase64, index) => {
      const base64Data = pdfBase64?.replace(
        /^data:application\/pdf;base64,/,
        ""
      );
      const pdfBlob = base64ToBlob(base64Data, "application/pdf");
      zip.file(`${label[index]}.pdf`, pdfBlob);
    });

    const zipBlob = await zip.generateAsync({ type: "blob" });
    setLoading(false);
    saveAs(zipBlob, "FileDocument.zip");
  };

  const [activeTab, setActiveTab] = useState("containerList");
  const [containerData, setContainerData] = useState();
  const toggleTab = () => {
    setActiveTab((prevTab) =>
      prevTab === "containerList" ? "otherDocument" : "containerList"
    );
  };

  const {
    mutate,
    isLoading,
    isError,
    isSuccess,
    data: uploadData,
    error,
  } = useMutation(
    (formData) =>
      axios.post(
        `https://api.2kvirtualworld.com/BESTTIMBERSDEV/containersfile/upload`,
        formData, // Send FormData directly
        {
          headers: { "Content-Type": "multipart/form-data" }, // Required for file uploads
        }
      ),
    {
      onSuccess: (responseData) => {
        console.log("File uploaded to server", responseData);
      },
      onError: (error) => {
        console.log("Error uploading file", error);
      },
    }
  );

  // const downloadPdf = () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       if (
  //         !containerData ||
  //         (!containerData && containerData?.containerImage?.length)
  //       ) {
  //         console.error("No container data or images found!");
  //         return reject(new Error("No container data available"));
  //       }

  //       const doc = new jsPDF("p", "mm", "a5");
  //       const pageWidth = doc.internal.pageSize.getWidth();
  //       const imageWidth = 90;
  //       const imageHeight = 70;
  //       const xPosition = (pageWidth - imageWidth) / 2;

  //       let yPosition = 10;
  //       let imagesPerPage = 0;
  //       const maxImagesPerPage = 2; // Maximum 2 images per page

  //       containerData &&
  //         containerData?.containerImage?.forEach((imageBase64, index) => {
  //           if (imageBase64) {
  //             let validBase64 = imageBase64.startsWith("data:image")
  //               ? imageBase64
  //               : `data:image/jpeg;base64,${imageBase64}`;

  //             console.log(
  //               `Adding Image ${index + 1}:`,
  //               validBase64.substring(0, 50)
  //             ); // Debugging

  //             // Add Image
  //             doc.addImage(
  //               validBase64,
  //               "JPEG",
  //               xPosition,
  //               yPosition,
  //               imageWidth,
  //               imageHeight
  //             );

  //             yPosition += imageHeight + 10; // Move to position for next image

  //             imagesPerPage++;

  //             // If two images are added, start a new page
  //             if (
  //               imagesPerPage === maxImagesPerPage &&
  //               index !== containerData.containerImage.length - 1
  //             ) {
  //               doc.addPage(); // Add new page
  //               yPosition = 10; // Reset y-position for new page
  //               imagesPerPage = 0; // Reset counter
  //             }
  //           }
  //         });

  //       // Add Final Text on Last Page
  //       doc.addPage(); // Move to last page
  //       doc.setFontSize(14);
  //       doc.text(
  //         `Container Name: ${containerData?.containerNo || "N/A"}`,
  //         pageWidth / 2,
  //         20,
  //         { align: "center" }
  //       );
  //       doc.text(
  //         `Avg CFT/Girth: ${containerData?.avgCftAvgGirth || "N/A"}`,
  //         pageWidth / 2,
  //         30,
  //         { align: "center" }
  //       );

  //       const pdfBlob = doc.output("blob");
  //       resolve(pdfBlob);
  //     } catch (error) {
  //       console.error("Error generating PDF:", error);
  //       reject(error);
  //     }
  //   });
  // };
  const downloadPdf = () => {
    return new Promise((resolve, reject) => {
      try {
        if (
          !containerData ||
          (!containerData && containerData?.containerImage) ||
          (containerData && containerData?.containerImage?.length === 0)
        ) {
          console.error("No container data or images found!");
          return reject(new Error("No container data available"));
        }

        // Initialize jsPDF with A5 dimensions
        const doc = new jsPDF("p", "mm", "a5");
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const imageWidth = 100;
        const imageHeight = 87;
        const xPosition = (pageWidth - imageWidth) / 2;

        let yPosition = 10; // Initial vertical position
        let imagesPerPage = 0;
        const maxImagesPerPage = 2; // Maximum images per page

        // Add container images to the PDF
        containerData &&
          containerData?.containerImage?.forEach((imageBase64, index) => {
            if (imageBase64) {
              const validBase64 = imageBase64.startsWith("data:image")
                ? imageBase64
                : `data:image/jpeg;base64,${imageBase64}`;

              console.log(
                `Adding Image ${index + 1}:`,
                validBase64.substring(0, 50)
              );

              // Add image at specified position
              doc.addImage(
                validBase64,
                "JPEG",
                xPosition,
                yPosition,
                imageWidth,
                imageHeight
              );

              yPosition += imageHeight + 10;
              imagesPerPage++;

              // If maximum images reached and there are more images, add a new page
              if (
                imagesPerPage === maxImagesPerPage &&
                index !== containerData?.containerImage?.length - 1
              ) {
                doc.addPage();
                yPosition = 10; // Reset y-position for the new page
                imagesPerPage = 0;
              }
            }
          });

        // Calculate the space required for the final text (e.g., 20 mm)
        const textRequiredHeight = 20;
        if (yPosition + textRequiredHeight > pageHeight) {
          // Not enough space on the current page, add a new page
          doc.addPage();
          yPosition = 10;
        }

        // Add final text on the current page
        doc.setFontSize(14);
        doc.text(
          `Container Name: ${containerData.containerNo || "N/A"}`,
          pageWidth / 2,
          yPosition,
          {
            align: "center",
          }
        );
        doc.text(
          `Avg CFT/Girth: ${containerData.avgCftAvgGirth || "N/A"}`,
          pageWidth / 2,
          yPosition + 10,
          {
            align: "center",
          }
        );

        // Output the PDF as a blob
        const pdfBlob = doc.output("blob");
        resolve(pdfBlob);
      } catch (error) {
        console.error("Error generating PDF:", error);
        reject(error);
      }
    });
  };

  // };
  const shareOnWhatsApp = async (containerName, avgCftGirth) => {
    try {
      // Generate the PDF blob
      const pdfBlob = await downloadPdf();

      // Create FormData for file upload
      const formData = new FormData();
      formData.append("file", pdfBlob, "invoice.pdf");
      formData.append("containerName", containerName); // Add containerName
      formData.append("avgCftGirth", avgCftGirth);
      // Upload the PDF and get the file URL
      mutate(formData, {
        onSuccess: (response) => {
          let fileUrl = response?.data || "";

          if (!fileUrl) {
            console.error("No file URL received from the server");
            return;
          }

          // Replace the localhost URL with production URL
          const newBaseUrl = "https://api.2kvirtualworld.com/BESTTIMBERSDEV";
          fileUrl = fileUrl.replace("http://localhost:8080/hostel", newBaseUrl);

          // WhatsApp message with the invoice download link
          const message = `Here is your invoice. Click here to download: ${fileUrl}`;

          // Construct WhatsApp URL without a pre-filled number
          const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            message
          )}`;

          // Open WhatsApp with the message
          window.open(whatsappUrl, "_blank");
        },
        onError: (error) => {
          console.log("Error uploading file:", error);
        },
      });
    } catch (error) {
      console.error("Error generating or sharing invoice:", error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-9 p-5"
                style={{
                  overflow: "auto",
                  scrollbarWidth: "none",
                  height: "85vh",
                }}
              >
                <div className="file-document-header-block py-2">
                  <label className="file-document-head">
                    {activeTab === "containerList"
                      ? "CONTAINER LIST"
                      : "OTHER DOCUMENT"}
                  </label>
                  <div className="d-flex gap-1">
                    <label
                      className="file-document-head"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        cursor: "pointer",
                      }}
                      onClick={toggleTab}
                    >
                      {activeTab === "containerList"
                        ? "OTHER DOCUMENT"
                        : "CONTAINER LIST"}{" "}
                      <img
                        src={Images?.listIcon}
                        style={{ width: "20px", height: "20px" }}
                      />
                    </label>
                  </div>
                </div>
                {activeTab == "containerList" && (
                  <>
                    <div className="file-document-table-block">
                      <label className="file-upload-name-title px-2">
                        {retrievedData && retrievedData?.fileName}
                      </label>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="file-document-table-heading --first-heading">
                                S.NO
                              </th>
                              <th className="file-document-table-heading">
                                CONTAINER NAME
                              </th>
                              <th className="file-document-table-heading">
                                AVG LENGTH
                              </th>
                              <th className="file-document-table-heading">
                                AVG CFT / GRITH
                              </th>
                              <th className="file-document-table-heading">
                                PICES
                              </th>
                              <th className="file-document-table-heading --last-heading">
                                SHARE
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {retrievedData &&
                              retrievedData?.userForm?.map((data, index) => {
                                console.log("data", data);
                                return (
                                  <tr key={index}>
                                    <td className="file-document-table-data">
                                      {index + 1}
                                    </td>
                                    <td className="file-document-table-data">
                                      {data?.containerNo}
                                    </td>
                                    <td className="file-document-table-data">
                                      {data?.averageLength}
                                    </td>
                                    <td className="file-document-table-data">
                                      {data?.avgCftAvgGirth}
                                    </td>
                                    <td className="file-document-table-data">
                                      {data?.pcs}
                                    </td>
                                    <td className="file-document-table-data">
                                      <div className="d-flex align-items-center justify-content-center gap-2">
                                        {isLoading ? (
                                          <i class="fa fa-spinner fa-spin"></i>
                                        ) : (
                                          <>
                                            <img
                                              src={Images?.shareIcon}
                                              className="file-share-icon"
                                              alt="no-image"
                                              onClick={() => {
                                                setContainerData(data);
                                                shareOnWhatsApp(
                                                  data?.containerNo,
                                                  data?.avgCftAvgGirth
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                        <img
                                          src={Images?.viewIcon}
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            navigate("/filesdownload", {
                                              state: {
                                                imgValue: data,
                                              },
                                            });
                                          }}
                                          alt="no-image"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
                {activeTab == "otherDocument" && (
                  <>
                    {array?.length == 0 || formImg?.length == 0 ? (
                      <>
                        <div className="row mt-5">
                          <h3
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: 500,
                            }}
                          >
                            There is no file available. Please upload the file
                          </h3>
                        </div>
                      </>
                    ) : (
                      <div className="row mt-5">
                        {array?.map((ele, i) => (
                          <div className="col-lg-4 p-3">
                            {array[i] == null ? (
                              <></>
                            ) : (
                              <div
                                onClick={() =>
                                  navigate("/filesdownload", {
                                    state: { files: ele, count: 10 },
                                  })
                                }
                                style={{
                                  background: "#fff",
                                  filter:
                                    "drop-shadow(-3px -3px 4px rgba(0, 0, 0, 0.25)) drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25))",
                                  borderRadius: "10px",
                                  position: "relative",
                                  cursor: "pointer",
                                  overflow: "hidden",
                                }}
                              >
                                <div
                                  style={{ textAlign: "center", height: 130 }}
                                >
                                  <iframe
                                    onClick={() =>
                                      navigate("/filesdownload", { state: ele })
                                    }
                                    scrolling="no"
                                    src={pdfUrl}
                                    // src={data:text/html,<html><body style="margin:0;"><img src='${pdfUrl}' style='width:100%;height:100%;'/></body></html>}
                                    className="copy_docconfi"
                                    type="application/pdf"
                                    width="240px"
                                    height="130px"
                                    style={{
                                      border: "none",
                                      overflow: "hidden",
                                      background: "#fff",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    background: "#543310",
                                    padding: "5px 20px",
                                    // textAlign:'center',
                                    borderRadius: "0px 0px 10px 10px",
                                  }}
                                >
                                  <p style={{ margin: 0, color: "#fff" }}>
                                    {label[i]}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                        {formImg?.map((ele, i) => {
                          return (
                            <>
                              {ele?.containerImage?.length > 1 ? (
                                <div className="col-lg-4 p-3">
                                  <div
                                    style={{
                                      background: "#fff",
                                      filter:
                                        "drop-shadow(-3px -3px 4px rgba(0, 0, 0, 0.25)) drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25))",
                                      borderRadius: "10px",
                                      position: "relative",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: "center",
                                        height: 130,
                                        overflow: "hidden",
                                      }}
                                    >
                                      <div
                                        onClick={() =>
                                          navigate("/filesdownload", {
                                            state: {
                                              imgValue: ele?.containerImage,
                                            },
                                          })
                                        }
                                        style={{
                                          height: "100%",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        {ele?.containerImage?.map((val) => {
                                          console.log("value", val);
                                          return (
                                            <>
                                              <div>
                                                <img
                                                  style={{
                                                    border: "none",
                                                    overflowY: "scroll",
                                                    background: "#fff",
                                                    width: "100px",
                                                  }}
                                                  src={`data:image/png;base64,${val}`}
                                                  alt={ele?.containerNo}
                                                />
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        background: "#543310",
                                        padding: "5px 20px",
                                        // textAlign:'center',
                                        borderRadius: "0px 0px 10px 10px",
                                      }}
                                    >
                                      <p style={{ margin: 0, color: "#fff" }}>
                                        {`CON : ${ele?.containerNo}, GIRTH : ${ele?.avgCftAvgGirth}`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}
                    <div className="mt-3 text-center">
                      {/* {loading ? <p>Downloading...</p> : null} */}
                      <button
                        className="btn_download_file"
                        onClick={() =>
                          downloadZipWithPdfs(
                            array,
                            downloadFormImg,
                            // downloadFormNo,
                            "documents"
                          )
                        }
                      >
                        {loading ? "Loading..." : "Download All"}
                      </button>
                    </div>
                  </>
                )}
              </div>

              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  {/* <SimpleBar style={{ maxHeight: 300 }}> */}

                  <RightSidebar
                    setIsCount={setIsCount}
                    islabel="FILE DOCUMENT"
                  />
                  {/* </SimpleBar> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PLPassword />
    </>
  );
};

export default FileDocumentPage;
