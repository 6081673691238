import React, { useRef } from "react";
// import { ToWords } from "number-to-words";
import { ToWords } from 'to-words';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
export default function FormA1Form() {
  // const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  const retrievedData = useSelector((state) => state?.formdata)

  // const amountInWords = toWords(1050000) + ' Rupees';
  const sectionsRef = useRef([]);
  //  const handleDownload = async () => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   for (let i = 0; i < sectionsRef.current.length; i++) {
  //     const canvas = await html2canvas(sectionsRef.current[i], { scale: 2 });
  //     const imgData = canvas.toDataURL("image/png");
  //     const imgWidth = pdfWidth;
  //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;

  //     if (i > 0) {
  //       pdf.addPage();
  //     }

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   }

  //   pdf.save("Multi-page-document.pdf");
  // };'\
  const letters = new ToWords({
    localeCode: 'en-US',
  }
  )
  const handleGeneratePdf = async () => {
    alert("Download Start");
    const pdf = new jsPDF("p", "mm", "a4");
    const options = {
      scale: 2,
      useCORS: true,
    };

    const captureDiv = async (id) => {
      const element = document.getElementById(id);
      if (element) {
        const canvas = await html2canvas(element, options);
        return canvas.toDataURL("image/png");
      }
      return null;
    };

    const imgData1 = await captureDiv("sheet1-content");
    const imgData2 = await captureDiv("sheet2-content");
    const imgData3 = await captureDiv("sheet3-content");
    const imgData4 = await captureDiv("sheet4-content");
    const imgData5 = await captureDiv("sheet5-content");
    const imgData6 = await captureDiv("sheet6-content");

    const imgWidth = 210; // A4 width in mm
    const addImageToPdf = (imgData, addPage = false) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imgData;
        img.onload = () => {
          const imgHeight = (img.height * imgWidth) / img.width;
          if (addPage) pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          resolve();
        };
      });
    };

    if (imgData1) await addImageToPdf(imgData1);
    if (imgData2) await addImageToPdf(imgData2, true);
    if (imgData3) await addImageToPdf(imgData3, true);
    if (imgData4) await addImageToPdf(imgData4, true);
    if (imgData5) await addImageToPdf(imgData5, true);
    if (imgData6) await addImageToPdf(imgData6, true);
    // if (imgData1) {
    //   const img1 = new Image();
    //   img1.src = imgData1;
    //   img1.onload = () => {
    //     const imgHeight = (img1.height * imgWidth) / img1.width;
    //     pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight);

    //     if (imgData2) {
    //       pdf.addPage();
    //       const img2 = new Image();
    //       img2.src = imgData2;
    //       img2.onload = () => {
    //         const imgHeight2 = (img2.height * imgWidth) / img2.width;
    //         pdf.addImage(imgData2, "PNG", 0, 0, imgWidth, imgHeight2);
    //         pdf.save("combined_divs.pdf");
    //       };
    //     } else {
    //       pdf.save("ForwardContract.pdf");
    //     }
    //   };
    // }
    pdf.save("Form A1.pdf");
  };
  // let words = ;
  return (
    <>
      <div style={{ height: "auto", overflow: "hidden" }}>
        {/* <div ref={el => sectionsRef.current[0] = el} style={{ height: '100px', backgroundColor: 'lightblue' }}>Section 1</div> */}
        <div
          // ref={(el) => (sectionsRef.current[0] = el)}
          id="sheet1-content"
          className="status-waiver-block  "
        >
          <div className="px-4" style={{ height: "1310px",position:"relative" }} >
          <p className="file-name-title">{retrievedData && retrievedData?.fileName}</p>
            <div className="d-flex flex-column py-4">
              <p className="status-waiver-content fs-4 pb-2">
                I. Outward Remittances
              </p>
              <p className="status-waiver-content py-2">
                III.2. Form A1 (Application for remittance towards Trade
                Transactions – Advance remittance towards Import, Bill
                Settlement -Direct Bill/Collection through Bank and LC issuance)
              </p>
              <p className="text-secondary pt-2">For Office use:</p>
              <table className="">
                <thead className="bg-white text-center">
                  <th className="formA1_OfficeUse_headerTable">AD Code No</th>
                  <th className="formA1_OfficeUse_headerTable">Form No</th>
                  <th className="formA1_OfficeUse_headerTable">Currency</th>
                  <th className="formA1_OfficeUse_headerTable">Amount</th>
                  <th className="formA1_OfficeUse_headerTable">
                    Equivalent to Rs
                  </th>
                </thead>
                <tbody>
                  <td className="text-secondary formA1_OfficeUse_BodyTable ">
                    {" "}
                  </td>
                  <td className="text-secondary formA1_OfficeUse_BodyTable ">
                    {" "}
                  </td>
                  <td className="text-secondary formA1_OfficeUse_BodyTable ">
                    {" "}
                  </td>
                  <td className="text-secondary formA1_OfficeUse_BodyTable ">
                    {" "}
                  </td>
                  <td className="text-secondary formA1_OfficeUse_BodyTable ">
                    {" "}
                  </td>
                </tbody>
                <thead className="bg-white text-center">
                  <th className="formA1_OfficeUse_headerTable">Customer ID</th>
                  <th className="formA1_OfficeUse_headerTable">
                    Transaction type
                  </th>
                  <th className="formA1_OfficeUse_headerTable">
                    TR/FWC Amount
                  </th>
                  <th className="formA1_OfficeUse_headerTable">TR/FWC Rate</th>
                  <th className="formA1_OfficeUse_headerTable">
                    TR/FWC Ref no.
                  </th>
                </thead>
                <tbody>
                  <td className="text-secondary formA1_OfficeUse_BodyTable">
                    {" "}
                  </td>
                  <td
                    className="text-secondary formA1_OfficeUse_BodyTable ps-4"
                    style={{ textDecoration: "underLine" }}
                  >
                    TT/DD{" "}
                  </td>
                  <td className="text-secondary formA1_OfficeUse_BodyTable">
                    {" "}
                  </td>
                  <td className="text-secondary formA1_OfficeUse_BodyTable">
                    {" "}
                  </td>
                  <td className="text-secondary formA1_OfficeUse_BodyTable">
                    {" "}
                  </td>
                </tbody>
              </table>
              <div>
                <p className="status-waiver-content text-end">
                  (In case of a Firm / Company obtain this application on their
                  Letterhead)
                </p>
                <p className="status-waiver-content fs-5 mt-4 py-2">
                  The Branch Manager,
                </p>
                <p className="status-waiver-content fs-6 mt-2 py-1">
                  South Indian Bank._________________Branch
                </p>
                <p className="status-waiver-content fs-6 mt-2 py-2 text-center text-decoration-underline">
                  Sub: Application for Advance Remittance towards Imports /Bill
                  Settlement (Direct
                </p>
                <p className="status-waiver-content fs-6 text-center text-decoration-underline py-2">
                  Receipt/Collection through Bank) against Imports into India/LC
                  Issuance
                </p>
              </div>
              <div className="pt-3 ">
                <p className="status-waiver-content">
                  We hereby request you to remit /issue Demand draft as per the
                  details given below:
                </p>
                <table className="sblc-table my-2 py-2 ">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td className="formA1-table-data">
                        Importer Exporter Code
                      </td>
                      <td className="formA1-table-data">0414021878</td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">Currency and Amount</td>
                      <td className="formA1-table-data">
                        USD
                        {retrievedData && retrievedData?.bgAmtAndInterestAmt
                          ? retrievedData?.bgAmtAndInterestAmt
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">Amount in Words</td>
                      <td className="formA1-table-data">
                        {letters.convert(retrievedData && retrievedData?.bgAmtAndInterestAmt
                          , { currency: true })}

                      </td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">Applicant Name</td>
                      <td className="formA1-table-data"> BEST TIMBERS</td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        Applicant Operative A/c No.
                      </td>
                      <td className="formA1-table-data">0138084000000004</td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">Beneficiary Name</td>
                      <td className="formA1-table-data">
                        {" "}
                        {retrievedData && retrievedData?.exporter
                          ? retrievedData?.exporter
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        Beneficiary Address (full Address with country)
                      </td>
                      <td className="formA1-table-data"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        Beneficiary Bank Name, Address and Swift Code
                      </td>
                      <td className="formA1-table-data"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        Beneficiary Bank A/c no./IBAN
                      </td>
                      <td className="formA1-table-data"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        SORT CODE / BSB NO. / TRANSIT NO.
                      </td>
                      <td className="formA1-table-data"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        Correspondent/Intermediary Bank Name and BIC CODE
                        (optional)
                      </td>
                      <td className="formA1-table-data"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        Foreign Bank charges (OUR / BEN / SHA)
                      </td>
                      <td className="formA1-table-data"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-data">
                        Goods Freely importable (Yes / No).
                      </td>
                      <td className="formA1-table-data">
                        If No, enclose original exchange control copy of license
                        and mention license details. Import licence particulars
                        to be filled in Part A
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <div ref={el => sectionsRef.current[1] = el} style={{ height: '100px', backgroundColor: 'lightgreen' }}>Section 2</div> */}
        <div
          // ref={(el) => (sectionsRef.current[1] = el)}
          id="sheet2-content"
          className="status-waiver-block mt-2 py-3 "
        >
          <div className="px-4" style={{ height: "1310px" }}>
            <div className="">
              <table className="sblc-table my-2  py-2">
                <tbody>
                  <tr>
                    <td className="formA1-table-data" style={{ width: "42%" }}>
                      Whether or not Third Party involved
                    </td>
                    <td className="formA1-table-data"></td>
                  </tr>

                  <tr>
                    <td className="formA1-table-data">
                      If yes, Name, Address and country of such third party
                      supplier/consignor
                    </td>
                    <td className="formA1-table-data"></td>
                  </tr>
                  <tr>
                    <td className="formA1-table-data">
                      Reference Bill ID in case of DA Bill Settlement
                    </td>
                    <td className="formA1-table-data"></td>
                  </tr>
                </tbody>
              </table>
              <div className="pt-2">
                <p className="status-waiver-content fs-6 mt-2 text-center">
                  Details of goods imported or to be imported into India
                </p>
                <p className="status-waiver-content fs-5 my-2 text-center">
                  Section A :{" "}
                  <span className="fs-6"> Import License particulars </span>
                </p>
                <table border="1" style={{ width: "90%" }}>
                  <thead></thead>
                  <tbody className="sblc-table">
                    <tr>
                      <td className="formA1-table-sectionA" rowSpan="3">
                        Import License
                      </td>
                      <td className="formA1-table-sectionA" colSpan="2">
                        Prefix
                      </td>
                      <td className="formA1-table-sectionA" colSpan="5">
                        License Number
                      </td>
                      <td className="formA1-table-sectionA" colSpan="5">
                        Suffixes
                      </td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA">1</td>
                      <td className="formA1-table-sectionA">2</td>
                      <td className="formA1-table-sectionA" colSpan="5"></td>
                      <td className="formA1-table-sectionA">1</td>
                      <td className="formA1-table-sectionA">2</td>
                      <td className="formA1-table-sectionA">3</td>
                      <td className="formA1-table-sectionA">4</td>
                      <td className="formA1-table-sectionA">5</td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA"></td>
                      <td className="formA1-table-sectionA"></td>
                      <td className="formA1-table-sectionA" colSpan="5"></td>
                      <td className="formA1-table-sectionA"></td>
                      <td className="formA1-table-sectionA"></td>
                      <td className="formA1-table-sectionA"></td>
                      <td className="formA1-table-sectionA"></td>
                      <td className="formA1-table-sectionA"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA" rowSpan="2">
                        Date of Issue
                      </td>
                      <td className="formA1-table-sectionA" colSpan="2">
                        Date
                      </td>
                      <td className="formA1-table-sectionA" colSpan="5">
                        Month
                      </td>
                      <td className="formA1-table-sectionA" colSpan="5">
                        Year
                      </td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA" colSpan="2"></td>
                      <td className="formA1-table-sectionA" colSpan="5"></td>
                      <td className="formA1-table-sectionA" colSpan="5"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA" rowSpan="2">
                        Date of Expiry
                      </td>
                      <td className="formA1-table-sectionA" colSpan="2">
                        Date
                      </td>
                      <td className="formA1-table-sectionA" colSpan="5">
                        Month
                      </td>
                      <td className="formA1-table-sectionA" colSpan="5">
                        Year
                      </td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA" colSpan="2"></td>
                      <td className="formA1-table-sectionA" colSpan="5"></td>
                      <td className="formA1-table-sectionA" colSpan="5"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA" colSpan="3">
                        Face Value of license
                      </td>
                      <td className="formA1-table-sectionA" colSpan="10"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionA" colSpan="3">
                        Amount to be endorsed (in Rs) *
                      </td>
                      <td className="formA1-table-sectionA" colSpan="10"></td>
                    </tr>
                  </tbody>
                </table>
                <p className="status-waiver-content mt-2">
                  *Actual amount endorsed in rupees against each license
                  involved, should be stated against the row.
                </p>
                <p className="status-waiver-content pt-2">
                  I/We hereby declare that total quantity we have already
                  imported and which we are going to import is well within the
                  limits permitted under the license
                </p>
                <p className="status-waiver-content pt-2">
                  Note: If more than one license is involved, particulars of all
                  licenses should be furnished. If the space is inadequate,
                  separate statement may be attached. The amount utilized
                  against each license should invariably be indicated.
                </p>
              </div>
              <div className="pt-2">
                <p className="status-waiver-content fs-5 my-2 text-center">
                  Section B : <span className="fs-6"> Import particulars </span>
                </p>
                <table
                  border="1"
                  className="sblc-table"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td className="formA1-table-sectionB" colSpan="2">
                        Invoice Details
                      </td>
                      {/* <td></td> */}
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB"> - No. and Date</td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        {" "}
                        - Terms (CIF, FOB, C&F etc)
                      </td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB"> - Currency</td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB"> - Amount</td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        Quantity of Goods
                      </td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        Description of Goods
                      </td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        Harmonised system of classification
                      </td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        Country of Origin of goods
                      </td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        Country from which goods are consigned
                      </td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        Mode of transport (air, sea port, rail, river etc)
                      </td>
                      <td className="formA1-table-sectionB"></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionB">
                        Date of shipment (if not known approx. date)
                      </td>
                      <td
                        className="formA1-table-sectionB"
                        style={{ width: "50%" }}
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="pt-2">
                <p className="status-waiver-content fs-5 mt-2 text-center">
                  Section C : <span className="fs-6">Other particulars </span>
                </p>
                <p className="status-waiver-content text-start">
                  1. Details of forward purchase contract, if any, booked
                  against the import
                </p>
                <table
                  border="1"
                  className="sblc-table"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      >
                        Number of contract
                      </td>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      ></td>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      >
                        Date of contract
                      </td>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      >
                        Currency of Contract
                      </td>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      ></td>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      >
                        Amount of contract
                      </td>
                      <td
                        className="formA1-table-sectionC"
                        style={{ width: "25%" }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="formA1-table-sectionC" colSpan="2">
                        Balance available under the contract
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <div ref={el => sectionsRef.current[2] = el} style={{ height: '100px', backgroundColor: 'lightcoral' }}>Section 3</div> */}
        <div
          // ref={(el) => (sectionsRef.current[2] = el)}
          id="sheet3-content"
          className="status-waiver-block mt-2 py-5 "
        >
          <div className="px-4" style={{ height: "1310px" }}>
            <p className="status-waiver-content ">
              2. If remittance to be made is less than invoice value, reasons
              thereof (i.e. part remittance, instalment
            </p>
            <p className="status-waiver-content">
              etc.) :
              ___________________________________________________________________________________________________{" "}
            </p>
            <p className="status-waiver-content fs-5 pt-3">DEBIT AUTHORITY</p>
            <p className="status-waiver-content pt-3">
              We request you to debit my/our current/ cash credit/ /EEFC Account
              No._______________________ for _______________________ and the
              balance amount to my/ our INR Account No._______________________
              along with your charges*.
            </p>
            <p className="status-waiver-content fs-6 pt-3">
              For Remittances on Account of Import Bill Settlement (Direct
              Receipt/Collection through Bank)
            </p>
            <p className="status-waiver-content ">
              We request that the payment for the DA/DP Bill be made as per
              instructions of overseas bank as mentioned in Covering Schedule of
              Bill sent for collection. In case of receipt of bills by us
              directly from overseas supplier we hereby declare that the receipt
              is in accordance with extant RBI Guidelines for Receipt of import
              documents by the importer directly from overseas suppliers as
              mentioned in RBI Master irection on Import of Goods and Services
              dated 01 January 2016 and amendments thereto.
            </p>
          </div>
        </div>

        <div
          // ref={(el) => (sectionsRef.current[3] = el)}
          id="sheet4-content"
          className="status-waiver-block mt-2 py-5 px-5"
        >
          <div className="px-4 d-flex flex-column" style={{ height: "1310px" }}>
            <div className="d-flex flex-column py-2">
              <p className="status-waiver-content py-2 ">
                Stating hereby the provisions of the RBI Master Direction on
                Import of Goods and Services whereby we are eligible to receive
                the import bill directly from the suppliers: (Select
                provision(s) that applies)
              </p>
              <p className="status-waiver-content ">
                i. Value of the Import bill does not exceed USD 300000.00
              </p>
              <p className="status-waiver-content ">
                ii. Supplier is our parent company and import bill is received
                from their end,
              </p>
              <p className="status-waiver-content ">
                iii. We are Status Holder Exporter.
              </p>
              <p className="status-waiver-content ">
                iv. We are an SEZ Unit or a 100% EOU.
              </p>
              <p className="status-waiver-content ">
                v. We are a private limited company, PSU or other class of
                limited company.
              </p>
            </div>
            <div className="pt-3 d-flex flex-column py-2">
              <p className="status-waiver-content ">
                We also inform that the Bills are being settled within the time
                limit prescribed for settlement of import Bills as per extant
                RBI guidelines. (or)
              </p>
              <p className="status-waiver-content ">
                Given that the bills are being settled with delay due
                to____________________ (specify reason) we request the Bank to
                process the payment based on requests for extensions submitted
                along with this request. (or) Since the bills are being settled
                after delay of more than 3 years from date of shipment, we
                attach herewith the RBI approval for settling the overseas
                supplier after the prescribed time limit allowed.{" "}
                <span className="fs-6"> (select appropriately)</span>
              </p>
            </div>
            <div className="pt-3 d-flex flex-column">
              <p className="status-waiver-content fs-6 pb-3">
                II. DECLARATION – CUM - UNDERTAKING
              </p>
              <p className="status-waiver-content d-flex flex-column">
                I/We hereby declare that the statements made by me/us on this
                form are true and that I/we have not applied for the same
                remittance through any other bank.
                <br />
                All rules and regulations issued under FEMA governing imports
                has been / will be adhered by me /us. Further in case of
                non-import of goods, we undertake to repatriate the proceeds to
                India.
                <br />
                I/We declare that the import licence/s against which the
                remittance is sought is/are valid and has/have not been
                cancelled by DGFT.
                <br />
                I/We declare that the goods to which this application relates
                will be imported into India on my/our own account
                <br />
                I/We declare that the invoice value of the goods which is
                declared on this form is the real value of the goods
                imported/being importer/to be imported into India.
                <br />
                I/We undertake to produce within three months to the authorised
                dealer the relative Exchange Control copy of Bill of Entry/ Post
                parcel wrapper/ Courier wrapper
                <br />
                The goods imported/being imported by us are not covered under
                Negative list of imports as per Foreign Trade Policy in
                prevalence.
                <br />
                We are eligible to import the above mentioned goods under the
                current Foreign Trade Policy in Place. <br />
                The said goods imported/being imported by us are not restricted
                for import through specific licensing under the above mentioned
                policy and amendments.
                <br />
                I/We also undertake to repatriate the amount to India in the
                event of Non-Import of goods within six months from date of
                remittance.
                <br />
                I/We agree that in case the beneficiary account is maintained in
                other than remitting currency, the intermediary bank may convert
                the currency to beneficiary account designated currency.
                <br />
                Remittance/Bill settlement transaction. If later on, it is found
                that the transaction is an MTT, on account of change in terms or
                other circumstances, we undertake to provide the end-to-end
                details of MTT along with CA Certificate for circumstances
                leading to the transaction being converted as an MTT along with
                taxability of the funds which were sent from the Bank as import
                payment.
                <br />
                We also hereby declare that the use of borrowed funds, if any,
                for the transaction is completely in good faith and not with any
                intention to defraud the Bank or wilfully default on payments
                due to the Bank on account of any losses which we may suffer in
                the event of the transaction leading to such losses.
                <br />
                We also undertake to indemnify and/or re-imburse the Bank on any
                reputational and/or financial losses that the Bank may face on
                account of any misrepresentation/ non-disclosure made by us vide
                this request for remittance. We also understand the Bank has the
                right to initiate reporting to RBI/External Agencies and/or
                initiate legal action as a remedy to any losses which Bank may
                have suffered as a result of misrepresentation/non-disclosure on
                our side.
                <br />
              </p>
            </div>
          </div>
        </div>

        <div
          // ref={(el) => (sectionsRef.current[4] = el)}
          id="sheet5-content"
          className="status-waiver-block mt-2 py-5 px-5"
        >
          <div
            className="px-4 d-flex flex-column justify-content-between"
            style={{ height: "1310px" }}
          >
            <div>
              <p className="status-waiver-content fs-6">
                The declaration-cum-undertaking under Sec 10(5), Chapter III of
                FEMA, 1999 is enclosed as under:
              </p>
              <p className="status-waiver-content pt-3">
                I/We declare that the captioned transaction does not involve and
                is not designed for the purpose of any contravention or evasion
                of the provision of the Foreign Exchange management Act 1999 or
                any rule, regulations, notification, direction or order issued
                there under.
              </p>
              <p className="status-waiver-content pt-3">
                I/We also hereby agree and undertake to give such
                information/documents as will reasonably satisfy you about this
                transaction in terms of the above declaration. I/We also
                understand that if I/We refuse to comply with any such
                requirement or make only unsatisfactory compliance therewith,
                the bank shall refuse in writing to undertake the transaction
                and shall if it has reason to believe that any
                contravention/evasion is contemplated by me/us report the matter
                to Reserve Bank of India.
              </p>
              <p className="status-waiver-content pt-3">
                I/We declare and also understand that the foreign exchange to be
                acquired by me/us pursuant to this application shall be used by
                me/us only for the purpose for which it is acquired and that the
                conditions subject to which the exchange is granted will be
                complied with
              </p>
              <p className="status-waiver-content pt-1">
                {" "}
                <span className="fs-6 font-weight-bold">
                  OFAC Declaration:{" "}
                </span>{" "}
                In this connection, we declare, confirm and undertake that:
              </p>
              <p className="status-waiver-content">
                i) I/We hereby declare that the payment initiated complies with
                guidelines of US Treasury Office of Foreign Assets Control and
                does not directly or indirectly involve a sanctions target.
                <br />
                ii) I/We declare that the transaction does not have linkage with
                specially designated nations and blocked person (SDN)/ countries
                listed under OFAC/UN sanctions in any manner. I/we undertake not
                to hold the bank responsible for any of its actions or inactions
                in respect of the OFAC linked/UN sanctioned transactions.
                <br />
                iii) We also understand that as per OFAC guidelines, any
                transaction in which a sanctions target has an interest, and
                that come within the person’s possession or control, even if the
                person is an intermediary and the underlying transaction does
                not otherwise involve a U.S. person/institution, can come under
                the OFAC scanner resulting in stringent actions against persons
                found to be facilitating such transactions.
                <br />
                iv) We further declare that we shall furnish any material
                information relating to this transaction as required by any
                authority/government/agency, now or in future. Also we authorize
                South Indian Bank Ltd to make available any of the material
                information pertains our transaction to any
                authority/agency/entity without referring the matter to us.
                <br />
                v) We will not be holding the Bank liable for any losses which
                we may have to bear on account of the Bank refusing to handle
                the requested transaction on account of their internal
                guidelines on OFAC.
                <br />
                vi) We also undertake to indemnify and/or re-imburse the Bank on
                any reputational and/or financial losses that the Bank may face
                on account of any misrepresentation/ non-disclosure made by us
                vide this request for remittance. We also understand the Bank
                has the right to initiate reporting to RBI/External Agencies
                and/or initiate legal action as a remedy to any losses which
                Bank may have suffered as a result of
                misrepresentation/non-disclosure on our side.
                <br />
                **I/We further declare that the undersigned has the authority to
                give this application, declaration and undertaking on behalf of
                the firm/company.
              </p>
            </div>

            <div className="row mt-5 pt-5 status-waiver-content">
              <p className="col-6"> Place :</p>

              <p className="col-6 text-end">
                {" "}
                Yours faithfully, Date : <br />
                <p className="pt-4">
                  Signature:_______________________________
                  <br />
                  <br />
                  Name:_______________________________
                  <br />
                  <br />
                  Address:_______________________________
                </p>
              </p>
            </div>
          </div>
        </div>

        <div
          // ref={(el) => (sectionsRef.current[5] = el)}
          id="sheet6-content"
          className="status-waiver-block mt-2 py-5 px-5"
        >
          <div className="px-4" style={{ height: "1310px" }}>
            <div className=" status-waiver-content">
              <p>
                Encl.: Copy of Proforma Invoice or Contract duly signed by the
                supplier and countersigned by the Importer
              </p>
              <p>
                **Where the import is on behalf of Central/State Government
                Department or a company owned by Central/State
                Government/Statutory Corporation, Local Body, etc. the name of
                the Government Department, Corporation etc. should be stated.
              </p>
            </div>
            <i className=" status-waiver-content fs-5 text-decoration-underline ">
              FOR OFFICE USE
            </i>
            <p className=" status-waiver-content py-2 text-center fs-6 text-decoration-underline">
              Certificate to be Furnished by Authorised Dealer (Importer's
              Banker)
            </p>
            <div className="status-waiver-content ">
              <p className="mb-0">
                We hereby certify that <br /> Put a tick () in the through us
                relevant block collection
              </p>
              <p className="mb-0">
                <span className="me-4 "> (a)</span> this payment is
              </p>
              <p className="mb-0">
                <span className="me-4"> (i)</span> an advance remittance
              </p>
              <p className="mb-0">
                <span className="me-4"> (ii)</span> in retirement of bills under
                Letter of Credit opened through
              </p>
              <p className="mb-0">
                <span className="me-4"> (iii)</span> against documents received
                through our medium for
              </p>
              <p className="mb-0">
                <span className="me-4"> (iv)</span> on account of documents
                received direct by the applicant/s against undertaking furnished
                by the latter to submit Customs-stamped Exchange Control copy of
                Bill of Entry of Post Parcel / courier wrapper (attached)
                submitted by the latter
              </p>
              <p className="mb-0">
                <span className="me-4"> (v)</span> on account of documents
                received direct by the applicant/s against Customs-stamped
                Exchange Control copy of Bill of Entry/post parcel/courier
                wrapper (attached) submitted by the latter
              </p>
              <p className="mb-0">
                <span className="me-4">(vi) </span>
                ________________________________________________________________________________
              </p>
              <p className="mb-0">
                <span className="me-4">(vii) </span>
                (any other case, to be explained)
              </p>
              <p className="mb-0">
                <span className="me-4">(b)</span>
                all the Exchange Control regulations applicable to the
                remittance have been complied with
              </p>
              <p className="mb-0">
                <span className="me-4"> (c)</span>
                the payment to the supplier of the goods has been* made will be*
              </p>
              <p className="mb-0">
                through_____________________________________________________________________________{" "}
              </p>
              <p className="mb-0">(Name & Address of the foreign bank)</p>
              <p className="mb-0">
                We also certify/undertake that the relevant Customs-stamped
                Exchange Control copy of Bill of Entry or post parcel/courier
                wrapper
              </p>
              <p className="mb-0">
                □ shall be verified by us <br /> within three months [vide
                certificate <br /> (a)(ii) and (iii) above].
              </p>
              <p className="mb-0">
                □ has been verified [vide certificate (a) (v) above]. <br />
                □ shall be obtained from the applicant/s <br /> within three
                months [vide certificate (a) (i) and (iv) <br /> above].
              </p>
            </div>
            <div className="row mt-5 pt-3">
              <div
                className="d-flex status-waiver-content  pb-5"
                style={{ borderBottom: "2px solid rgba(255, 255, 255, 0.555)" }}
              >
                <div className="col-6 ">
                  <div className="p-5 ms-5 m-3">
                    <span className="border p-1 text-center status-waiver-content ">
                      Stamp
                    </span>
                  </div>
                  <p className="mb-0">Date:.................... </p>
                  <p className="mb-0">
                    dealer............................................{" "}
                  </p>
                </div>

                <div className="col-6 ps-5 ">
                  <p>(Signature of Authorised Official)</p>
                  <p className="">
                    {" "}
                    <span className="text-start">Name</span> <br />{" "}
                    .............................................................
                  </p>
                  <p className="mb-0">
                    {" "}
                    Designation.......................................................
                    <br />
                    ..........................................................
                  </p>
                  <p className="mb-0">
                    {" "}
                    <span className="text-start">
                      Name and Address of{" "}
                    </span>{" "}
                    <br />{" "}
                    .............................................................
                  </p>
                </div>
              </div>
              <div className="status-waiver-content">
                <p>* Strike out item not applicable</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center py-5">
        <button
          className="summary-download-btn"
          onClick={() => handleGeneratePdf()}
        >
          Download
        </button>
      </div>
    </>
  );
}
