import React, { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
// import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import InputField from "../inputField/InputField";
import Strings from "../../res/String";
import PopUpModal from "../popupmodal/PopUpModal";
import Images from "../../assets";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

// Fetch user details
export const fetchUserDetails = async () => {
  return await axios.get(
    `${process.env.REACT_APP_HOST}/packinglistdetails/all`
  );
};

export default function NewPackingList(props) {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      newTeakRounds: [{ length: "", girth: "", cbm: "" }],
      newTeakSwan: [
        {
          width: "",
          thickness: "",
          length: "",
          noOfPieces: "",
          cbm: "",
        },
      ],
      totalTeakNos: "0",
      totalTeakCbm: "0.00",
    },
  });
  const {
    fields: userFields,
    append: appendUser,
    remove: removeUser,
    replace: replaceUser,
  } = useFieldArray({
    control,
    name: "newTeakRounds",
  });

  const {
    fields: teakFields,
    append: appendTeak,
    remove: removeTeak,
    replace: replaceTeak,
  } = useFieldArray({
    control,
    name: "newTeakSwan",
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log("state", state);
  const values = useSelector((state) => state.loginData);
  // console.log("values", values?.role);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const formData = watch();

  //  setValue For Edit

  useEffect(() => {
    if (state && state?.id) {
      setValue("fileId", state?.fileId);
      setValue("date", state?.date);
      setValue("agentName", state?.agentName);
      setValue("partyName", state?.partyName);
      setValue("pkl", state?.pkl);
      setValue("cbm", state?.cbm);
      setValue("comm", state?.comm);
      setValue("load", state?.loadvalue);
      setValue("received", state?.received);
      setValue("paidAmount", state?.paidAmount);
      setValue("balanceAmount", state?.balanceAmount);
      setValue("rate", state?.rate);
      setValue("mts", state?.mts);
      setValue("container", state?.container);
      setValue("lorry", state?.lorry);
      setValue("distance", state?.distance);
      setValue("shipTo", state?.shipTo);
      setValue("pincode", state?.pincode);
      setValue("fromName", state?.fromName);
      setValue("fromMobileNumber", state?.fromMobileNumber);
      setValue("fromStreet", state?.fromStreet);
      setValue("fromLandmark", state?.fromLandmark);
      setValue("fromCity", state?.fromCity);
      setValue("fromDistrict", state?.fromDistrict);
      setValue("fromPincode", state?.fromPincode);
      setValue("toName", state?.toName);
      setValue("toMobileNumber", state?.toMobileNumber);
      setValue("toStreet", state?.toStreet);
      setValue("toLandmark", state?.toLandmark);
      setValue("toCity", state?.toCity);
      setValue("toDistrict", state?.toDistrict);
      setValue("toPincode", state?.toPincode);
      setValue("gstNumber", state?.gstNumber);
      setValue("avgLength", state?.avgLength);
      setValue("avgGirth", state?.avgGirth);
      setValue("totalCbm", state?.totalCbm);
      setValue("totalNos", state?.totalNos);
      setValue("avgCft", state?.avgCft);
      setValue("swanNos", state?.swanNos);
      setValue("swanCbm", state?.swanCbm);
      setValue("totalTeakNos", state?.totalTeakNos);
      setValue("totalTeakCbm", state?.totalTeakCbm);
      setValue("amount", state?.amount);
      setValue("sumMTS", state?.sumMTS);
      setValue("invCBM", state?.invCBM);
      setValue("invRate", state?.invRate);
      setValue("invAmount", state?.invAmount);
      setValue("plusGst", state?.plusGst);
      if (state?.newTeakRounds?.length > 0) {
        replaceUser(
          state?.newTeakRounds?.map((item) => ({
            length: item?.length || "",
            girth: item.girth || "",
            cbm: item.cbm || "",
          }))
        );
      }
      if (state?.newTeakSwan?.length > 0) {
        replaceTeak(
          state?.newTeakSwan?.map((item) => ({
            width: item?.width || "",
            thickness: item?.thickness || "",
            length: item?.length || "",
            noOfPieces: item?.noOfPieces || "",
            cbm: item?.cbm || "",
          }))
        );
      }
    } else {
      const savedFormData = localStorage.getItem("formData"); // Get saved data
      if (savedFormData) {
        const parsedData = JSON.parse(savedFormData); // Parse the saved data
        Object.keys(parsedData)?.forEach((fieldName) => {
          setValue(fieldName, parsedData[fieldName]); // Restore each field
        });
      }
    }
  }, [state, setValue, replaceUser, replaceTeak]);
  // SETVALUE END

  // POST API INTEGRATION START
  const { mutate, isSuccess, reset, isLoading } = useMutation(
    async (postData) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/packinglist/save`,
          postData
        );
        return response.data; // Return data if needed
      } catch (error) {
        console.error("Error in mutation function:", error);
        throw error; // Re-throw the error so that onError is triggered
      }
    },
    {
      onSuccess: () => {
        setPopupOpen(true);
        reset();
        console.log("User added successfully.");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  // POST API END

  // PUT API
  const UpdateAPI = useMutation(
    async (postdata) => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_HOST}/packinglist/update/${
            state && state?.id
          }`,
          postdata
        );
        return response.data; // Return the response to the mutation
      } catch (error) {
        console.error("Error while updating data:", error);
        throw error; // Re-throw the error so that `onError` in useMutation is triggered
      }
    },
    {
      onSuccess: (val) => {
        console.log("Data updated successfully:", val);
        setPopupOpen(!isPopupOpen); // Close popup after success
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  // PUT API END

  // Step state (persisted in localStorage)
  const [step, setStep] = useState(
    () => Number(localStorage.getItem("currentStep")) || 1
  );
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const [appendvalue, setAppendValue] = useState("TEAK ROUND LOGS");
  // const { load, received, paidAmount, comm, rate, mts } = watch();

  // Refs for length and girth fields
  const lengthRefs = useRef([]);
  const girthRefs = useRef([]);

  // Handle 'Enter' key press for moving focus between fields
  const handleKeyPress = (event, index, fieldType) => {
    if (event.key === "Enter") {
      event.preventDefault();

      // If in length field, move focus to girth field of the same row
      if (fieldType === "length") {
        girthRefs.current[index].focus();
      }

      // If in girth field, move focus to the length field of the next row or append a new row if last
      else if (fieldType === "girth") {
        if (index === userFields?.length - 1) {
          // If it's the last row, append a new row and focus on the new length input
          appendUser({ length: "", girth: "", cbm: "" });
          setTimeout(() => {
            lengthRefs.current[index + 1].focus(); // Focus on new row's length input
          }, 0);
        } else {
          // Otherwise, move focus to the next row's length input
          lengthRefs.current[index + 1].focus();
        }
      }
    }
  };

  // Refs for each input field (Width, Thickness, Length, Pieces)
  const fieldRefs = useRef({
    width: [],
    thickness: [],
    length: [],
    noOfPieces: [],
  });

  // Handle 'Enter' key press to navigate between fields and append a new row if necessary
  const handleEnterKeyPress = (event, fieldType, index) => {
    if (event.key === "Enter") {
      event.preventDefault();

      switch (fieldType) {
        case "width":
          // Move to thickness of the same row
          fieldRefs.current.thickness[index].focus();
          break;

        case "thickness":
          // Move to length of the same row
          fieldRefs.current.length[index].focus();
          break;

        case "length":
          // Move to noOfPieces of the same row
          fieldRefs.current.noOfPieces[index].focus();
          break;

        case "noOfPieces":
          // If it's the last row, append a new row and move to the width of the new row
          if (index === teakFields?.length - 1) {
            appendTeak({
              width: "",
              thickness: "",
              length: "",
              noOfPieces: "",
              cbm: "",
            });
            setTimeout(() => {
              fieldRefs.current.width[index + 1].focus(); // Focus on new row's width input
            }, 0);
          } else {
            // Otherwise, move to the next row's width field
            fieldRefs.current.width[index + 1].focus();
          }
          break;

        default:
          break;
      }
    }
  };

  // Handle 'Enter' key press for appending fields
  // const handleKeyPress = (event, appendFunc, appendItem) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     appendFunc(appendItem);
  //     calculateValues();
  //   }
  // };

  const newTeakRounds = watch("newTeakRounds") || [];

  // calculate teak round logs

  // Calculate CBM and update totals/averages
  const calculateValues = () => {
    let totalLength = 0;
    let totalGirth = 0;
    let totalCbm = 0;

    newTeakRounds &&
      newTeakRounds?.forEach((item, index) => {
        const length = Number(item?.length) || 0;
        const girth = Number(item?.girth) || 0;
        const cbm = length && girth ? (length * girth * girth) / 16000000 : 0;

        // Update individual CBM for the row
        setValue(`newTeakRounds.${index}.cbm`, Number(cbm).toFixed(3));

        totalLength += length;
        totalGirth += girth;
        // totalCbm += cbm;
      });
    totalCbm =
      newTeakRounds &&
      newTeakRounds
        ?.reduce((total, item) => total + parseFloat(item?.cbm), 0)
        .toFixed(3);

    // Calculate averages and totals
    const totalNos = newTeakRounds && newTeakRounds?.length;
    const avgLength =
      totalNos > 0 ? (totalLength / totalNos).toFixed(2) : "0.000";
    const avgGirth =
      totalNos > 0 ? (totalGirth / totalNos).toFixed(2) : "0.000";
    const avgCft =
      totalNos > 0 ? ((totalCbm * 35.315) / totalNos).toFixed(2) : "0.000";

    // Update totals and averages dynamically
    setValue("avgLength", Number(avgLength).toFixed(2) || null);
    setValue("avgGirth", Number(avgGirth).toFixed(2) || null);
    setValue("totalCbm", Number(totalCbm).toFixed(3) || null);
    setValue("avgCft", Number(avgCft).toFixed(2) || null);
    setValue("totalNos", Number(totalNos) || null);
  };

  // Trigger calculations on newTeakRounds change
  useEffect(() => {
    calculateValues();
  }, [newTeakRounds]);

  // Calculation for account details

  const { load, received, paidAmount, comm, rate, mts, totalCbm } = watch();

  useEffect(() => {
    // Convert inputs to numbers (default to 0 to prevent NaN issues)
    const numTotalCbm = Number(totalCbm) || 0;
    const numRate = Number(rate) || 0;
    const numMts = Number(mts) || 0;
    const numLoad = Number(load) || 0;
    const numReceived = Number(received) || 0;
    const numPaidAmount = Number(paidAmount) || 0;
    const numComm = Number(comm) || 0;

    // Calculate total amount
    const amount = numTotalCbm * 35.315 * numRate;
    setValue("amount", amount.toFixed(2) || 0);

    // Calculate sum of mts
    const sumMts = numMts * 35.315 * numRate;
    setValue("sumMTS", sumMts.toFixed(2) || 0);

    // Calculate invoice CBM
    const invCbm = numTotalCbm - numMts;
    setValue("invCBM", invCbm.toFixed(3) || 0);

    // Calculate invoice amount
    const invAmount = amount - sumMts - numLoad;
    setValue("invAmount", invAmount.toFixed(2) || 0);

    // Calculate invoice rate (avoid division by zero)
    const invRate = invCbm !== 0 ? invAmount / invCbm : 0;
    setValue("invRate", invRate.toFixed(2) || 0);

    // Calculate balance amount correctly
    const balanceAmt = numLoad + sumMts - numComm - numReceived + numPaidAmount;
    setValue("balanceAmount", balanceAmt.toFixed(2) || 0);
  }, [setValue, totalCbm, load, received, paidAmount, comm, rate, mts]);

  // useEffect(() => {}, [load, sumofmts]);

  // Save form data to localStorage
  const saveFormDataToLocalStorage = () => {
    const formData = watch();
    // console.log("formData", formData);
    if (!state) {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
  };

  // Watch the fields to get updated values dynamically
  const teakRoundFields = watch("newTeakSwan");

  const [aggregatedData, setAggregatedData] = useState([]); // To store sizes, nos, cbm dynamically

  // Function to calculate aggregated data dynamically
  const calculateAndUpdate = () => {
    const dataMap = {}; // To store unique size combinations

    // Iterate over all the fields (teakRoundFields)
    teakRoundFields?.forEach((item, index) => {
      const { width, thickness, noOfPieces, cbm, length } = item;

      // If length, width, thickness, and noOfPieces are available
      if (length && width && thickness && noOfPieces) {
        const swancbm =
          (Number(length) *
            Number(width) *
            Number(thickness) *
            Number(noOfPieces)) /
          144 /
          35.315;

        // Set the calculated CBM value to the form
        setValue(
          `newTeakSwan.${index}.cbm`,
          Number(swancbm).toFixed(3) || null
        );

        // Generate key for this size combination (width X thickness)
        const key = `${width}X${thickness}`;

        // Check if this size combination already exists in the map
        if (!dataMap[key]) {
          // Create a new entry if it doesn't exist
          dataMap[key] = {
            sizes: key,
            nos: parseFloat(noOfPieces) || 0,
            cbm: parseFloat(swancbm) || 0,
          };
        } else {
          // Sum up nos and cbm if the size already exists
          dataMap[key].nos += parseFloat(noOfPieces) || 0;
          dataMap[key].cbm += parseFloat(swancbm) || 0;
        }
      }
    });

    // Convert dataMap to an array (containing the aggregated data)
    const aggregated = Object.values(dataMap);
    setAggregatedData(aggregated); // Update aggregated data state

    //  Calculate total nos (ensure numbers are valid)
    const totalNos = aggregated?.reduce(
      (sum, item) => sum + (Number(item?.nos) || 0),
      0
    );
    setValue("totalTeakNos", totalNos || null);

    //  Calculate total CBM (avoid NaN issues)
    const totalCbm =
      teakRoundFields?.reduce(
        (sum, item) => sum + (parseFloat(item?.cbm) || 0),
        0
      ) || 0;

    setValue("totalTeakCbm", totalCbm.toFixed(3) || 0);
  };

  // Trigger calculation dynamically whenever 'newTeakSwan' fields change
  useEffect(() => {
    calculateAndUpdate();
  }, [teakRoundFields]); // Dependency array triggers when 'newTeakSwan' fields change

  const onSubmit = (data) => {
    const formData = {
      // ...data,
      fileId: data?.fileId || null,
      date: data?.date || null,
      agentName: data?.agentName || null,
      partyName: data?.partyName || null,
      pkl: data?.pkl || null,
      cbm: data?.cbm || null,
      comm: data?.comm || null,
      loadvalue: data?.load || null,
      received: data?.received || null,
      paidAmount: data?.paidAmount || null,
      balanceAmount: data?.balanceAmount || null,
      rate: data?.rate || null,
      mts: data?.mts || null,
      container: data?.container || null,
      lorry: data?.lorry || null,
      distance: data?.distance || null,
      shipTo: data?.shipTo || null,
      pincode: data?.pincode || null,
      fromName: data?.fromName || null,
      fromMobileNumber: data?.fromMobileNumber || null,
      fromStreet: data?.fromStreet || null,
      fromLandmark: data?.fromLandmark || null,
      fromCity: data?.fromCity || null,
      fromDistrict: data?.fromDistrict || null,
      fromPincode: data?.fromPincode || null,
      toName: data?.toName || null,
      toMobileNumber: data?.toMobileNumber || null,
      toStreet: data?.toStreet || null,
      toLandmark: data?.toLandmark || null,
      toCity: data?.toCity || null,
      toDistrict: data?.toDistrict || null,
      toPincode: data?.toPincode || null,
      gstNumber: data?.gstNumber || null,
      item: data?.item || null,
      avgLength: data?.avgLength || null,
      avgGirth: data?.avgGirth || null,
      totalCbm: data?.totalCbm || null,
      totalNos: data?.totalNos || null,
      avgCft: data?.avgCft || null,
      swanSizes: data?.swanSizes || null,
      swanNos: data?.swanNos || null,
      swanCbm: data?.swanCbm || null,
      totalTeakNos: data?.totalTeakNos || null,
      totalTeakCbm: data?.totalTeakCbm || null,
      newTeakRounds: data?.newTeakRounds || null,
      newTeakSwan: data?.newTeakSwan || null,
      aggregatedSizes: aggregatedData || null,
      amount: data?.amount || null,
      sumMTS: data?.sumMTS || null,
      invCBM: data?.invCBM || null,
      invRate: data?.invRate || null,
      invAmount: data?.invAmount || null,
      plusGst: data?.plusGst || null,
      distance: data?.distance || null,
    };
    if (state && state?.id != null) {
      UpdateAPI.mutate(formData);
    } else {
      mutate(formData);
    }

    localStorage.removeItem("formData");
    localStorage.removeItem("currentStep");
  };

  const goToNextStep = () => {
    saveFormDataToLocalStorage(); // Save current form data

    setStep((prevStep) => {
      const newStep = prevStep + 1;
      localStorage.setItem("currentStep", newStep); // Save step to localStorage
      return newStep;
    });
    if (props?.divref?.current) {
      props?.divref?.current.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const goToPreviousStep = () => {
    saveFormDataToLocalStorage();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setStep((prevStep) => {
      const newStep = prevStep - 1;
      localStorage.setItem("currentStep", newStep); // Save step to localStorage
      return newStep;
    });
    if (props?.divref?.current) {
      props?.divref?.current.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="px-3 py-4">
        {values?.role === "limitedAdmin" && step === 1 && goToNextStep()}

        {step === 1 && (
          <>
            <div className="dataEntryInputContainer py-2">
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.fieldId}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="fileId"
                    render={(field) => (
                      <InputField
                        dataEntryInputField="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="F02"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.date}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="date"
                    render={(field) => (
                      <InputField
                        dataEntryInputField="data-entry-input-field"
                        {...field}
                        type="date"
                        placeholder="DD/MM/YYYY"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.agentName}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="agentName"
                    render={(field) => (
                      <InputField
                        dataEntryInputField="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="KOMMI"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.partyName}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="partyName"
                    render={(field) => (
                      <InputField
                        dataEntryInputField="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="ARW"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.pkl}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="pkl"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="12"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.cmb}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="cbm"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="13.790"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.comm}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="comm"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="14745.00"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.load}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="load"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="100000.00"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="dataEntryInputContainer py-2">
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.received}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="received"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="85255.00"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="packing-list-input-block">
                <label className="paking-list-label">
                  {Strings.paidAmount}
                </label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="paidAmount"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="85255.00"
                      />
                    )}
                  />
                </div>
              </div>
              {/* <div /> */}
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="packing-list-input-block">
                <label className="paking-list-label">
                  {Strings.BalanceAmount}
                </label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="balanceAmount"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="85255.00"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.rate}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="rate"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="1191"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="packing-list-input-block">
                <label className="paking-list-label">{Strings.mts}</label>
                <div className="packing-list-input">
                  <Controller
                    control={control}
                    name="mts"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="number"
                        step="any"
                        placeholder="2"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="packingListInputContainer py-3 d-flex justify-content-center gap-2">
              {/* <button
              type="button"
              className="packing-list-back-btn"
              onClick={goToPreviousStep}
            >
              Back
            </button> */}

              <button
                type="button"
                className="packing-list-next-btn"
                onClick={goToNextStep}
              >
                Next
              </button>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block">
                <label className="data-entry-label --add-user-label">
                  {/* {Strings.container} */}
                  CONTAINER NO :
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="container"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="F02"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.lorry}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="lorry"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="1630"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.dist}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="distance"
                    render={({ field }) => (
                      <input
                        {...field}
                        className="data-entry-input-field"
                        type="number"
                        step="any"
                        placeholder="28"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.shipto}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="shipTo"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="198/3, KOLATHHUR ,CHENNAI"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2 d-flex justify-content-end">
              <div className="data-entry-input-block">
                <label className="data-entry-label --add-user-label">
                  {/* {Strings.pincode} */}
                  SHIP TO PINCODE
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="pincode"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="636121"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <h6 className="packing-list-sub-heading py-3">
              {Strings?.fromdetails}
            </h6>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.fromname}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="fromName"
                    render={({ field }) => (
                      <input
                        {...field}
                        className="data-entry-input-field"
                        type="text"
                        placeholder="Best Timbers"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.number}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="fromMobileNumber"
                    render={({ field }) => (
                      <input
                        {...field}
                        className="data-entry-input-field"
                        type="tel"
                        placeholder="8010489435"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.street}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="fromStreet"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="Chennai"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {/* {Strings.landmark} */}
                  AREA
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="fromLandmark"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="Nehru Timber Market, Choolai"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.city}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="fromCity"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="CHENNAI"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {/* {Strings.district} */}
                  STATE
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="fromDistrict"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="TAMILNADU"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.pincodetwo}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="fromPincode"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="600112"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <h6 className="packing-list-sub-heading py-3">
              {Strings?.todetails}
            </h6>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.toname}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="toName"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="SARAVANA PACKING WORKS"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.number}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="toMobileNumber"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="tel"
                        placeholder="7010489434"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.street}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="toStreet"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="AYAPPAKAM ROAD"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {/* {Strings.landmark} */}
                  AREA
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="toLandmark"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="MGR PURAM"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.city}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="toCity"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="TIRUVALLUR"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {/* {Strings.district} */}
                  STATE
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="toDistrict"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="TAMILNADU"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="dataEntryInputContainer py-2">
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.pincodetwo}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="toPincode"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="600058"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="data-entry-input-block --packinglist-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.gstnumber}
                </label>
                <div className="data-entry-input --add-user-input">
                  <Controller
                    control={control}
                    name="gstNumber"
                    render={({ field }) => (
                      <input
                        className="data-entry-input-field"
                        {...field}
                        type="text"
                        placeholder="33GRDPS9509A1ZS"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="packingListInputContainer py-3 d-flex justify-content-center gap-2">
              {values?.role !== "limitedAdmin" && (
                <button
                  type="button"
                  className="packing-list-back-btn"
                  onClick={goToPreviousStep}
                >
                  Back
                </button>
              )}

              <button
                type="button"
                className="packing-list-next-btn"
                onClick={goToNextStep}
              >
                Next
              </button>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <div className="packingListInputContainer py-2">
              <div className="data-entry-input-block">
                <label className="data-entry-label --add-user-label">
                  {Strings.item}
                </label>
                <div className="data-entry-input --add-user-input">
                  <select
                    name="item"
                    className="data-entry-input-field"
                    value={appendvalue}
                    onChange={(e) => setAppendValue(e.target.value)}
                  >
                    <option value="TEAK ROUND LOGS">TEAK ROUND LOGS</option>
                    <option value="TEAK SWAN SIZE">TEAK SWAN SIZE</option>
                  </select>
                </div>
              </div>
            </div>
            <h6 className="packing-list-sub-heading py-3">
              {Strings?.packinglistentry}
            </h6>

            {appendvalue === "TEAK ROUND LOGS" && (
              <>
                <div className="packingListInputContainer py-2 gap-2">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {userFields?.map((field, index) => (
                        <tr key={field?.id}>
                          <td className="packinglist-append-label">
                            S.NO: {index + 1}
                          </td>

                          <td className="packinglist-append-label">
                            {Strings?.length}
                          </td>
                          <td className="packinglist-append-input">
                            {/* <Controller
                              name={`newTeakRounds.${index}.length`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="175"
                                  className="data-entry-input-field"
                                  style={{ width: "120px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateValues();
                                  }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, appendUser, {
                                      length: "",
                                      girth: "",
                                      cbm: "",
                                    })
                                  }
                                />
                              )}
                            /> */}

                            <Controller
                              name={`newTeakRounds.${index}.length`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="175"
                                  step="any"
                                  className="data-entry-input-field"
                                  style={{ width: "120px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateValues();
                                  }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, index, "length")
                                  }
                                  ref={(el) => (lengthRefs.current[index] = el)} // Set ref for length input
                                />
                              )}
                            />
                          </td>

                          <td className="packinglist-append-label">
                            {Strings?.girth}
                          </td>
                          <td className="packinglist-append-input">
                            {/* <Controller
                              name={`newTeakRounds.${index}.girth`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "120px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateValues();
                                  }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, appendUser, {
                                      length: "",
                                      girth: "",
                                      cbm: "",
                                    })
                                  }
                                />
                              )}
                            /> */}
                            <Controller
                              name={`newTeakRounds.${index}.girth`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "120px" }}
                                  step="any"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateValues();
                                  }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, index, "girth")
                                  }
                                  ref={(el) => (girthRefs.current[index] = el)} // Set ref for girth input
                                />
                              )}
                            />
                          </td>

                          <td className="packinglist-append-label">
                            {Strings?.cmb}
                          </td>
                          <td className="packinglist-append-input">
                            <Controller
                              name={`newTeakRounds.${index}.cbm`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  step="any"
                                  disabled
                                  readOnly
                                  className="data-entry-input-field"
                                  style={{
                                    width: "120px",
                                    outline: "none",
                                    border: "none",
                                  }}
                                  placeholder="0.00"
                                />
                              )}
                            />
                          </td>

                          <td className="packinglist-append-input">
                            {index !== 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  removeUser(index);
                                }}
                                style={{ padding: "5px", border: "none" }}
                              >
                                X
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}

                      {/* Average Length, Girth, CBM, etc. */}
                      <tr>
                        <td className="packinglist-append-label"></td>
                        <td className="packinglist-append-label">
                          {Strings.avglength}
                        </td>
                        <td className="packinglist-append-input">
                          <Controller
                            name="avgLength"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                step="any"
                                readOnly
                                className="data-entry-input-field"
                                style={{
                                  width: "120px",
                                  outline: "none",
                                  border: "none",
                                }}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>

                        <td className="packinglist-append-label">
                          {Strings.avggirth}
                        </td>
                        <td className="packinglist-append-input">
                          <Controller
                            name="avgGirth"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                step="any"
                                readOnly
                                className="data-entry-input-field"
                                style={{
                                  width: "120px",
                                  outline: "none",
                                  border: "none",
                                }}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>

                        <td className="packinglist-append-label">
                          {Strings.totalcbm}
                        </td>
                        <td className="packinglist-append-input">
                          <Controller
                            name="totalCbm"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                step="any"
                                readOnly
                                className="data-entry-input-field"
                                style={{
                                  width: "120px",
                                  outline: "none",
                                  border: "none",
                                }}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      </tr>

                      {/* Total Numbers and Avg CFT */}
                      <tr>
                        <td className="packinglist-append-label"></td>
                        <td className="packinglist-append-label">
                          {Strings.totalnos}
                        </td>
                        <td className="packinglist-append-input">
                          <Controller
                            name="totalNos"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                step="any"
                                readOnly
                                className="data-entry-input-field"
                                style={{
                                  width: "120px",
                                  outline: "none",
                                  border: "none",
                                }}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>

                        <td className="packinglist-append-label">
                          {Strings.avgcft}
                        </td>
                        <td className="packinglist-append-input">
                          <Controller
                            name="avgCft"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                step="any"
                                readOnly
                                className="data-entry-input-field"
                                style={{
                                  width: "120px",
                                  outline: "none",
                                  border: "none",
                                }}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {appendvalue === "TEAK SWAN SIZE" && (
              <>
                <div className="packingListInputContainer py-2 gap-2">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {teakFields?.map((field, index) => (
                        <tr key={field?.id}>
                          <td className="packinglist-append-label">
                            S.NO: {index + 1}
                          </td>

                          <td className="packinglist-append-label">
                            {/* {Strings?.width} */}
                            WIDTH
                          </td>
                          <td className="packinglist-append-input">
                            {/* <Controller
                              name={`newTeakSwan.${index}.width`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="175"
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onChange={(e) => {
                                    field.onChange(e); 
                                    calculateAndUpdate(); 
                                  }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, appendTeak, {
                                      width: "",
                                      thickness: "",
                                      length: "",
                                      noOfPieces: "",
                                      cbm: "",
                                      sizes: "",
                                    })
                                  }
                                />
                              )}
                            /> */}
                            <Controller
                              name={`newTeakSwan.${index}.width`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  step="any"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateAndUpdate();
                                  }}
                                  onKeyPress={(e) =>
                                    handleEnterKeyPress(e, "width", index)
                                  }
                                  ref={(el) =>
                                    (fieldRefs.current.width[index] = el)
                                  } // Set ref for width input
                                />
                              )}
                            />
                          </td>

                          <td className="packinglist-append-label">THICK</td>
                          <td className="packinglist-append-input">
                            {/* <Controller
                              name={`newTeakSwan.${index}.thickness`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateAndUpdate();
                                  }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, appendTeak, {
                                      width: "",
                                      thickness: "",
                                      length: "",
                                      noOfPieces: "",
                                      cbm: "",
                                      sizes: "",
                                    })
                                  }
                                />
                              )}
                            /> */}
                            <Controller
                              name={`newTeakSwan.${index}.thickness`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  step="any"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateAndUpdate();
                                  }}
                                  onKeyPress={(e) =>
                                    handleEnterKeyPress(e, "thickness", index)
                                  }
                                  ref={(el) =>
                                    (fieldRefs.current.thickness[index] = el)
                                  } // Set ref for thickness input
                                />
                              )}
                            />
                          </td>

                          <td className="packinglist-append-label">
                            {/* {Strings?.length} */}
                            LENGTH
                          </td>
                          <td className="packinglist-append-input">
                            {/* <Controller
                              name={`newTeakSwan.${index}.length`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="77"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateAndUpdate();
                                  }}
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, appendTeak, {
                                      width: "",
                                      thickness: "",
                                      length: "",
                                      noOfPieces: "",
                                      cbm: "",
                                      sizes: "",
                                    })
                                  }
                                />
                              )}
                            /> */}
                            <Controller
                              name={`newTeakSwan.${index}.length`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  step="any"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateAndUpdate();
                                  }}
                                  onKeyPress={(e) =>
                                    handleEnterKeyPress(e, "length", index)
                                  }
                                  ref={(el) =>
                                    (fieldRefs.current.length[index] = el)
                                  } // Set ref for length input
                                />
                              )}
                            />
                          </td>
                          <td className="packinglist-append-label">
                            {/* {Strings?.noofpieces} */}
                            PICS
                          </td>
                          <td className="packinglist-append-input">
                            {/* <Controller
                              name={`newTeakSwan.${index}.noOfPieces`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateAndUpdate();
                                  }}
                                  onKeyPress={(e) =>
                                    handleKeyPress(e, appendTeak, {
                                      width: "",
                                      thickness: "",
                                      length: "",
                                      noOfPieces: "",
                                      cbm: "",
                                      sizes: "",
                                    })
                                  }
                                />
                              )}
                            /> */}
                            <Controller
                              name={`newTeakSwan.${index}.noOfPieces`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  step="any"
                                  placeholder="77"
                                  className="data-entry-input-field"
                                  style={{ width: "85px" }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateAndUpdate();
                                  }}
                                  onKeyPress={(e) =>
                                    handleEnterKeyPress(e, "noOfPieces", index)
                                  }
                                  ref={(el) =>
                                    (fieldRefs.current.noOfPieces[index] = el)
                                  } // Set ref for pieces input
                                />
                              )}
                            />
                          </td>
                          <td className="packinglist-append-label">
                            {/* {Strings?.cmb} */}
                            CBM
                          </td>
                          <td className="packinglist-append-input">
                            <Controller
                              name={`newTeakSwan.${index}.cbm`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="number"
                                  step="any"
                                  readOnly
                                  className="data-entry-input-field"
                                  style={{
                                    width: "85px",
                                    outline: "none",
                                    border: "none",
                                  }}
                                  placeholder="0.00"
                                />
                              )}
                            />
                          </td>

                          <td className="packinglist-append-input">
                            {index !== 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  removeTeak(index);
                                }}
                                style={{ padding: "5px", border: "none" }}
                              >
                                X
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}

                      {/* Average Length, Girth, CBM, etc. */}
                      {aggregatedData && aggregatedData?.length > 0 ? (
                        aggregatedData?.map((item, idx) => (
                          <>
                            <tr key={idx}>
                              <td className="packinglist-append-label"></td>

                              <td className="packinglist-append-label">
                                SIZES
                              </td>
                              <td className="packinglist-append-input">
                                <input
                                  type="number"
                                  step="any"
                                  name="swanSizes"
                                  readOnly
                                  value={item?.sizes}
                                  className="data-entry-input-field"
                                  style={{
                                    width: "85px",
                                    outline: "none",
                                    border: "none",
                                  }}
                                  placeholder="0.00"
                                />
                              </td>
                              <td className="packinglist-append-label">NOS</td>
                              <td className="packinglist-append-input">
                                <input
                                  type="number"
                                  step="any"
                                  name="swanNos"
                                  readOnly
                                  value={item?.nos}
                                  className="data-entry-input-field"
                                  style={{
                                    width: "85px",
                                    outline: "none",
                                    border: "none",
                                  }}
                                  placeholder="0.00"
                                />
                              </td>

                              <td className="packinglist-append-label">
                                {Strings.cmb}
                              </td>
                              <td className="packinglist-append-input">
                                <input
                                  type="number"
                                  step="any"
                                  name="swanCbm"
                                  readOnly
                                  value={item?.cbm?.toFixed(3)}
                                  className="data-entry-input-field"
                                  style={{
                                    width: "85px",
                                    outline: "none",
                                    border: "none",
                                  }}
                                  placeholder="0.00"
                                />
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td className="packinglist-append-label"></td>
                            <td className="packinglist-append-label">SIZES</td>
                            <td className="packinglist-append-input text-center">
                              -
                            </td>
                            <td className="packinglist-append-label">NOS</td>
                            <td className="packinglist-append-input text-center">
                              -
                            </td>
                            <td className="packinglist-append-label">CBM</td>
                            <td className="packinglist-append-input text-center">
                              -
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td className="packinglist-append-label"></td>

                        <td
                          colSpan="2"
                          className="packinglist-append-label"
                        ></td>

                        <td className="packinglist-append-label">TOT.NOS</td>
                        <td className="packinglist-append-input">
                          <Controller
                            name="totalTeakNos"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                step="any"
                                readOnly
                                className="data-entry-input-field"
                                style={{
                                  width: "85px",
                                  outline: "none",
                                  border: "none",
                                }}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>

                        <td className="packinglist-append-label">TOT.CBM</td>
                        <td className="packinglist-append-input">
                          <Controller
                            name="totalTeakCbm"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="number"
                                step="any"
                                readOnly
                                className="data-entry-input-field"
                                style={{
                                  width: "85px",
                                  outline: "none",
                                  border: "none",
                                }}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
            <div className="packingListInputContainer py-3 d-flex justify-content-center gap-2">
              <button
                type="button"
                className="packing-list-back-btn"
                onClick={goToPreviousStep}
              >
                Back
              </button>
              {values?.role === "limitedAdmin" ? (
                <>
                  <button type="submit" className="packing-list-next-btn">
                    {isLoading ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      <>Submit</>
                    )}
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="packing-list-next-btn"
                  onClick={goToNextStep}
                >
                  Next
                </button>
              )}
              {/* <button
              type="button"
              className="packing-list-next-btn"
              onClick={goToNextStep}
            >
              Next
            </button> */}
            </div>
          </>
        )}

        {step === 4 && (
          <>
            <h6 className="packing-list-sub-heading pt-1">ACCOUNT DETAILS</h6>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                <label className="packing-list-label">PKL :</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.pkl) || ""}
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">PARTY/AGENT :</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  type="text"
                  value={`${(formData && formData?.partyName) || ""} / ${
                    (formData && formData?.agentName) || ""
                  }`}
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">LORRY :</label>
              </div>
              <div className="col-2 p-0">
                {" "}
                <input
                  className="data-entry-input-field"
                  type="text"
                  readOnly
                  value={(formData && formData?.lorry) || ""}
                  style={{ border: "none", outline: "none" }}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                <label className="packing-list-label">{Strings.shipto}</label>
              </div>
              <div className="col-6 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.shipTo) || ""}
                  type="text"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                <label className="packing-list-label">{Strings.cmb}</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={
                    (formData && formData?.totalCbm) ||
                    (formData && formData?.totalTeakCbm) ||
                    ""
                  }
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">{Strings.rate}</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.rate) || ""}
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">{Strings.amt}</label>
              </div>
              <div className="col-2 p-0">
                <Controller
                  control={control}
                  name="amount"
                  render={({ field }) => (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      step="any"
                      readOnly
                      style={{ border: "none", outline: "none" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                <label className="packing-list-label">{Strings.mts}</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.mts) || null}
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2">
                <label className="packing-list-label">MTS AMOUNT : </label>
              </div>
              <div className="col-2 p-0">
                <Controller
                  control={control}
                  name="sumMTS"
                  render={({ field }) => (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      step="any"
                      readOnly
                      style={{ border: "none", outline: "none" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                <label className="packing-list-label">DISCOUNT :</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.load) || ""}
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.load) || ""}
                  type="text"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                {" "}
                <label className="packing-list-label">INV CBM :</label>
              </div>
              <div className="col-2 p-0">
                <Controller
                  control={control}
                  name="invCBM"
                  render={({ field }) => (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      step="any"
                      readOnly
                      style={{ border: "none", outline: "none" }}
                    />
                  )}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">INV RATE :</label>
              </div>
              <div className="col-2 p-0">
                <Controller
                  control={control}
                  name="invRate"
                  render={({ field }) => (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      step="any"
                      readOnly
                      style={{ border: "none", outline: "none" }}
                    />
                  )}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">INV AMOUNT :</label>
              </div>
              <div className="col-2 p-0">
                <Controller
                  control={control}
                  name="invAmount"
                  render={({ field }) => (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="number"
                      step="any"
                      readOnly
                      style={{ border: "none", outline: "none" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                <label className="packing-list-label">{Strings?.avgcft}</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.avgCft) || ""}
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">NOS :</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={
                    (formData && formData?.totalNos) ||
                    (formData && formData?.totalTeakNos) ||
                    ""
                  }
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">PLUS GST :</label>
              </div>
              <div className="col-2 p-0">
                <Controller
                  control={control}
                  name="plusGst"
                  render={({ field }) => (
                    <input
                      className="data-entry-input-field"
                      {...field}
                      type="text"
                      // readOnly
                      style={{ border: "none", outline: "none" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center py-2 px-2">
              <div className="col-2">
                <label className="packing-list-label">DISTANCE :</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.distance) || ""}
                  type="number"
                  step="any"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">PARTY PIN-CODE :</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.toPincode) || ""}
                  type="text"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
              <div className="col-2">
                <label className="packing-list-label">SHIP TO PIN-CODE :</label>
              </div>
              <div className="col-2 p-0">
                <input
                  className="data-entry-input-field"
                  value={(formData && formData?.pincode) || ""}
                  type="text"
                  readOnly
                  style={{ border: "none", outline: "none" }}
                />
              </div>
            </div>
            <div className="packingListInputContainer py-3 d-flex justify-content-center gap-2">
              {step > 1 && (
                <button
                  type="button"
                  className="packing-list-back-btn"
                  onClick={goToPreviousStep}
                >
                  Back
                </button>
              )}
              {step < 4 && (
                <button
                  type="button"
                  className="packing-list-next-btn"
                  onClick={goToNextStep}
                >
                  Next
                </button>
              )}
              {step === 4 && (
                <>
                  {/* <label
                    type="submit"
                    className="packing-list-back-btn"
                    onClick={downloadAcReport}
                  >
                    Download
                  </label> */}
                  <button type="submit" className="packing-list-next-btn">
                    {isLoading ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      <>Submit</>
                    )}
                  </button>
                </>
              )}
            </div>
          </>
        )}

        {/* <div className="packingListInputContainer py-3 d-flex justify-content-center gap-2">
          {step > 1 && (
            <button
              type="button"
              className="packing-list-back-btn"
              onClick={goToPreviousStep}
            >
              Back
            </button>
          )}
          {step < 4 && (
            <button
              type="button"
              className="packing-list-next-btn"
              onClick={goToNextStep}
            >
              Next
            </button>
          )}
          {step === 4 && (
            <>
              <label
                type="submit"
                className="packing-list-back-btn"
                onClick={downloadAcReport}
              >
                Download
              </label>
              <button type="submit" className="packing-list-next-btn">
                {isLoading ? (
                  <i class="fa fa-spinner fa-spin"></i>
                ) : (
                  <>Submit</>
                )}
              </button>
            </>
          )}
        </div> */}
      </form>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={Images?.logo}
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-2">
          {state && state?.id ? (
            <>
              <h6 className="popup-content mb-3">
                Packing List Has Been Updated Successfuly..
              </h6>
            </>
          ) : (
            <>
              <h6 className="popup-content mb-3">
                Packing List Has Been Added Successfuly..
              </h6>
            </>
          )}
          {(values?.role == "superAdmin" || values?.role == "admin") && (
            <>
              <button
                className="user-add-button mt-2 mb-2"
                onClick={() => {
                  navigate("/agentManagement");
                }}
              >
                OK
              </button>
            </>
          )}
          {values?.role == "limitedAdmin" && (
            <>
              <button
                className="user-add-button mt-2 mb-2"
                onClick={() => {
                  navigate("/");
                }}
              >
                OK
              </button>
            </>
          )}
        </div>
      </PopUpModal>
    </>
  );
}
