import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Strings from "../../res/String";
import InputField from "../inputField/InputField";
import Model from "../model/Model";
import Images from "../../assets";
import PopUpModal from "../popupmodal/PopUpModal";
import logo from "../../assets/img/timber_logo.png";
import axios from "axios";
import deleteIcon from "../../assets/img/delete.svg";
import searchIcon from "../../assets/img/search.png";
import addIcon from "../../assets/img/addUser.png";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import * as XLSX from "xlsx";

import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment";
import { light } from "@mui/material/styles/createPalette";
import { pink } from "@mui/material/colors";

// Fetch user details
export const fetchUserDetails = async () => {
  return await axios.get(`${process.env.REACT_APP_HOST}/packinglist/all`);
};

export default function AgentManagementCom() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusValue, setStatusValue] = useState();
  const [agentValue, setAgentValue] = useState();

  const [deleteIndex, setDeleteIndex] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [downloadPopUp, setDownloadPopUp] = useState(false);
  const [sharePopUp, setSharePopUp] = useState(false);
  const [popUpData, setPopUpData] = useState({
    key: "",
    items: "",
  });
  const values = useSelector((state) => state.loginData);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  const toggleShare = () => {
    setSharePopUp(!sharePopUp);
  };

  // console.log('fetchUserDetails',fetchUserDetails);

  // GET API
  const { data, isLoading, isError, refetch } = useQuery(
    "data",
    fetchUserDetails
  );
  let details = data && data?.data;

  const HandlerDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST}/packinglistdetails/bulk`,
        {
          data: deleteIndex,
        }
      );
      refetch();
      document.getElementById("myCheckbox").checked = false;
      setDeletePopUp(!deletePopUp);
      setPopupOpen(false);
      return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  let actionFilterValue =
    details &&
    details
      ?.filter((item) => {
        if (!statusValue && !agentValue) {
          // No filters applied, return all items
          return true;
        }
        if (statusValue && agentValue) {
          // Both status and agent filters applied
          return item?.status === statusValue && item?.agentName === agentValue;
        }
        if (statusValue) {
          // Only status filter applied
          return item?.status === statusValue;
        }
        if (agentValue) {
          // Only agent filter applied
          return item?.agentName === agentValue;
        }
      })
      ?.sort((a, b) => {
        // Ensure fileId is treated as a number or string
        const fileIdA = isNaN(Number(a.fileId)) ? a.fileId : Number(a.fileId);
        const fileIdB = isNaN(Number(b.fileId)) ? b.fileId : Number(b.fileId);

        // Sorting based on data type
        if (typeof fileIdA === "number" && typeof fileIdB === "number") {
          return fileIdA - fileIdB; // Numeric sort
        } else {
          return String(fileIdA).localeCompare(String(fileIdB)); // String sort
        }
      });

  const filteredData = actionFilterValue?.filter((item) =>
    item?.fileId?.toLowerCase().startsWith(searchTerm?.toLowerCase())
  );

  // Download Start
  const filterdataFile =
    details &&
    details?.filter((item) => {
      if (selectedRows?.length !== 0) {
        return selectedRows?.includes(item?.id);
      }
    });

  // DELETE API
  // Delete a user by ID
  const deleteUser = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST}packinglist/delete/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error deleting user with ID ${id}:`, error);
      throw error;
    }
  };

  // Delete multiple users
  const deleteUsers = async (ids) => {
    try {
      const results = await Promise.all(
        ids?.map((id) => {
          return deleteUser(id);
        })
      );
      return results; // Return all results after deletion
    } catch (error) {
      console.error("Error deleting multiple users:", error);
      throw error; // Re-throw the error for the mutation to handle
    }
  };
  // Mutation to delete users
  const mutation = useMutation({
    mutationFn: deleteUsers,
    onSuccess: () => {
      setSelectedRows([]); // Clear selected rows
      refetch();
    },
    onError: (error) => {
      console.error("Error deleting rows:", error);
      alert("Error deleting rows");
    },
  });

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.includes(id);
      if (isSelected) {
        return prevSelectedRows?.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };
  // Handle delete button click
  const handleDelete = () => {
    if (selectedRows?.length === 0) {
      alert("No rows selected");
      return;
    }
    mutation.mutate(selectedRows);
  };

  // DELETE API END

  const downloadExcel = () => {
    // Define headers
    const headers = [
      "S.no",
      "FILE ID",
      "DATE",
      "AGENT",
      "PARTY NAME",
      "PKL",
      "CBM",
      "COMM",
      "LOAD",
      "RECEIVED",
      "PAID",
      "BALANCE",
      "STATUS",
    ];

    // Map data to match the headers order
    const formattedData =
      selectedRows?.length !== 0
        ? filterdataFile?.map((item, index) => ({
            "S.no": index + 1,
            "FILE ID": item.fileId || "",
            DATE: item.date || "",
            AGENT: item.agentName || "",
            "PARTY NAME": item.partyName || "",
            PKL: item.pkl || "",
            CBM: item.cbm || "",
            COMM: item.comm || "",
            LOAD: item.load || "",
            RECEIVED: item.received || "",
            PAID: item.paidAmount || "",
            BALANCE: item.balanceAmount || "",
            STATUS: item.status || "",
          }))
        : filteredData &&
          filteredData?.map((item, index) => ({
            "S.no": index + 1,
            "FILE ID": item.fileId || "",
            DATE: item.date || "",
            AGENT: item.agentName || "",
            "PARTY NAME": item.partyName || "",
            PKL: item.pkl || "",
            CBM: item.cbm || "",
            COMM: item.comm || "",
            LOAD: item.load || "",
            RECEIVED: item.received || "",
            PAID: item.paidAmount || "",
            BALANCE: item.balanceAmount || "",
            STATUS: item.status || "",
          }));

    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, "Data.xlsx");
  };
  // Download End
  let totalCbm = 0;
  let totalComm = 0;
  let totalLoad = 0;
  let totalReceived = 0;
  let totalPaid = 0;
  let totalBalance = 0;

  const downloadAcReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Account Details");

    // Address Rows
    const addressRows = [
      [], // Empty rows for spacing
      [], // Empty rows for spacing
      ["", "", "", "ACCOUNT DETAILS", ""], // Title row
      [
        "",
        "",
        "",
        "PKL",
        popUpData?.items?.pkl,
        "PARTY/AGENT",
        `${popUpData?.items?.partyName}/${popUpData?.items?.agentName}`,
        "LORRY",
        popUpData?.items?.lorry,
      ],
      ["", "", "", "Ship To", popUpData?.items?.shipTo, "", "", "", ""],
      [
        "",
        "",
        "",
        "CBM",
        `${popUpData?.items?.totalCbm || popUpData?.items?.totalTeakCbm || ""}`,
        "RATE",
        popUpData?.items?.rate,
        "AMT",
        popUpData?.items?.amount,
      ],
      ["", "", "", "MTS", popUpData?.items?.mts, "", "", "", ""],
      ["", "", "", "DISCOUNT", popUpData?.items?.loadvalue, "", "", ""],
      [
        "",
        "",
        "",
        "INV CBM",
        popUpData?.items?.invCBM,
        "INV RATE",
        popUpData?.items?.invRate,
        "INV AMT",
        popUpData?.items?.invAmount,
      ],
      [
        "",
        "",
        "",
        "AVG CFT",
        popUpData?.items?.avgCft,
        "NOS",
        `${popUpData?.items?.totalNos || popUpData?.items?.totalTeakNos || ""}`,
        "PLUSGST",
        popUpData?.items?.plusGst,
      ],
      [
        "",
        "",
        "",
        "DIST",
        popUpData?.items?.distance,
        "PIN-PN",
        `${popUpData?.items?.fromPincode}`,
        `${popUpData?.items?.toPincode}`,
        "",
      ],
    ];

    // Add rows to the worksheet
    addressRows.forEach((row) => worksheet.addRow(row));

    // Function to apply styles
    const applyStyles = (
      cell,
      mergeRange,
      fontSize,
      isBold,
      backgroundColor
    ) => {
      if (cell.value) {
        cell.font = {
          bold: isBold,
          size: fontSize,
          color: { argb: "000000" }, // Black text color
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        if (backgroundColor) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: backgroundColor }, // Background color
          };
        }
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } }, // Top border
          left: { style: "thin", color: { argb: "000000" } }, // Left border
          bottom: { style: "thin", color: { argb: "000000" } }, // Bottom border
          right: { style: "thin", color: { argb: "000000" } }, // Right border
        };

        worksheet.mergeCells(mergeRange);
      }
    };

    // Apply styles to specific cells
    applyStyles(worksheet.getCell("D3"), "D3:I3", 14, true); // "ACCOUNT DETAILS" title
    applyStyles(worksheet.getCell("D4"), "D4:D4", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("D5"), "D5:D5", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("D6"), "D6:D6", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("D7"), "D7:D7", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("D8"), "D8:D8", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("D9"), "D9:D9", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("D10"), "D10:D10", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("D11"), "D11:D11", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("F4"), "F4:F4", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("F6"), "F6:F6", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("F9"), "F9:F9", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("F10"), "F10:F10", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("F11"), "F11:F11", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("H4"), "H4:H4", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("H6"), "H6:H6", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("H9"), "H9:H9", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("H10"), "H10:H10", 11, true, "C4D79B");
    applyStyles(worksheet.getCell("E4"), "E4:E4", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("E5"), "E5:I5", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("E6"), "E6:E6", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("E7"), "E7:I7", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("E8"), "E8:I8", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("E9"), "E9:E9", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("E10"), "E10:E10", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("E11"), "E11:E11", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("G4"), "G4:G4", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("G6"), "G6:G6", 11, false, "EBF1DE");

    applyStyles(worksheet.getCell("G9"), "G9:G9", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("G10"), "G10:G10", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("G11"), "G11:G11", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("H11"), "H11:H11", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("I4"), "I4:I4", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("I6"), "I6:I6", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("I9"), "I9:I9", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("I10"), "I10:I10", 11, false, "EBF1DE");
    applyStyles(worksheet.getCell("I11"), "I11:I11", 11, false, "EBF1DE");

    // Adjust column widths for better formatting
    worksheet.columns = [
      { width: 7 }, // Column A
      { width: 7 }, // Column B
      { width: 7 }, // Column C
      { width: 14 }, // Column D
      { width: 14 }, // Column E
      { width: 14 }, // Column F
      { width: 14 }, // Column G
      { width: 14 }, // Column H
      { width: 14 }, // Column I
    ];

    // Export the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Account_Details_Report.xlsx");
  };

  const handleWhatsAppClick = () => {
    const whatsappUrl = "https://api.whatsapp.com/";
    window.open(whatsappUrl, "_blank");
  };

  const handleGmailClick = () => {
    const gmailUrl = "mailto:";
    window.location.href = gmailUrl;
  };
  console.log("data---->", filteredData);
  return (
    <>
      {(values?.role === "superAdmin" || values?.role === "admin") && (
        <>
          <div className="headerTimeLine px-3 py-3">
            <div className="px-5 d-flex justify-content-between">
              <div className="d-flex search-block">
                <input
                  type="search"
                  className="table-search"
                  value={searchTerm}
                  placeholder="search by fileId"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="image_icon">
                  <img
                    src={searchIcon}
                    style={{ width: "100%", height: "100%" }}
                    alt="Search"
                  />
                </div>
              </div>
              <div
                className="d-flex gap-2 align-items-center"
                style={{ width: "50%" }}
              >
                <div className="add-user-btn-block">
                  <select
                    className="new-user-btn text-center"
                    onChange={(event) => {
                      setAgentValue(event.target.value);
                    }}
                  >
                    <option value="">Agent</option>
                    {[...new Set(details?.map((data) => data?.agentName))].map(
                      (agentName, index) => {
                        return (
                          <>
                            <option key={index} value={agentName}>
                              {agentName}
                            </option>
                          </>
                        );
                      }
                    )}
                  </select>
                </div>
                <div className="add-user-btn-block">
                  <select
                    className="new-user-btn text-center"
                    onChange={(event) => {
                      setStatusValue(event.target.value);
                    }}
                  >
                    <option value="">STATUS</option>
                    <option value="active">ACTIVE</option>
                    <option value="inactive">INACTIVE</option>
                  </select>
                </div>
                <div
                  className="add-user-btn-block"
                  onClick={() => navigate("/newPackingList")}
                >
                  <button className="new-user-btn">NEW PKL</button>
                </div>
                <div className="add-user-btn-block" onClick={downloadExcel}>
                  <button className="new-user-btn">DOWNLOAD</button>
                </div>
                {selectedRows?.length !== 0 ? (
                  <div className="add-user-btn-block">
                    <button
                      className="new-user-btn"
                      onClick={() => {
                        setPopupOpen(true);
                      }}
                    >
                      DELETE
                    </button>
                    <div className="image_icon">
                      <img src={deleteIcon} className="img-icon" alt="Delete" />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {values?.role == "limitedAdmin" && (
        <>
          <div className="headerTimeLine px-3 py-3">
            <div className="px-5 d-flex justify-content-between">
              <div className="d-flex search-block">
                <input
                  type="search"
                  className="table-search"
                  value={searchTerm}
                  placeholder="search by fileId"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="image_icon">
                  <img
                    src={searchIcon}
                    style={{ width: "100%", height: "100%" }}
                    alt="Search"
                  />
                </div>
              </div>
              <div
                className="d-flex gap-2 align-items-center"
                // style={{ width: "50%" }}
              >
                <div className="add-user-btn-block">
                  <select
                    className="new-user-btn text-center"
                    onChange={(event) => {
                      setAgentValue(event.target.value);
                    }}
                  >
                    <option value="">Agent</option>
                    {[...new Set(details?.map((data) => data?.agentName))].map(
                      (agentName, index) => {
                        // console.log("agentName", agentName);
                        return (
                          <>
                            <option key={index} value={agentName}>
                              {agentName}
                            </option>
                          </>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="px-5 mt-3">
        <div className="table-responsive view-table-block  px-1">
          {(values?.role == "superAdmin" || values?.role == "admin") && (
            <>
              <table className="table agent-management-table">
                <thead>
                  <tr style={{ position: "sticky", top: "0px" }}>
                    <th className="agent-view-user-table-head --first-head">
                      SELECT / EDIT
                    </th>
                    <th
                      className="agent-view-user-table-head"
                      style={{ minWidth: "40px" }}
                    >
                      S.NO
                    </th>

                    <th className="agent-view-user-table-head">FILE ID</th>
                    <th
                      className="agent-view-user-table-head"
                      style={{ minWidth: "90px" }}
                    >
                      DATE
                    </th>
                    <th className="agent-view-user-table-head">AGENT</th>
                    <th
                      className="agent-view-user-table-head"
                      style={{ minWidth: "145px" }}
                    >
                      PARTY NAME
                    </th>
                    <th className="agent-view-user-table-head">PKL</th>
                    <th className="agent-view-user-table-head">CBM</th>
                    <th className="agent-view-user-table-head">COMM</th>
                    <th className="agent-view-user-table-head">LOAD</th>
                    <th className="agent-view-user-table-head">RECEIVED</th>
                    <th className="agent-view-user-table-head">PAID</th>
                    <th className="agent-view-user-table-head">BALANCE</th>
                    <th className="agent-view-user-table-head">STATUS</th>
                    <th className="agent-view-user-table-head --last-head">
                      REPORT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <div
                      style={{ width: "200%", fontWeight: "900" }}
                      className="py-2 px-4"
                    >
                      L O A D I N G . . .
                    </div>
                  ) : filteredData?.length !== 0 ? (
                    filteredData &&
                    filteredData?.map((val, i) => {
                      totalCbm = Number(val?.cbm) + totalCbm;
                      totalBalance = Number(val?.balanceAmount) + totalBalance;
                      totalComm = Number(val?.comm) + totalComm;
                      totalLoad =
                        Number(val?.loadvalue) +
                        Number(val?.sumMTS) +
                        totalLoad;
                      totalReceived = Number(val?.received) + totalReceived;
                      totalPaid = Number(val?.paidAmount) + totalPaid;
                      return (
                        <tr key={val?.id} className="view-user-table-row">
                          <td className="agent-view-user-table-data">
                            <div className="d-flex align-items-center justify-content-center">
                              <input
                                type="checkbox"
                                checked={selectedRows?.includes(val?.id)}
                                onChange={() => handleCheckboxChange(val?.id)}
                              />
                              <div
                                onClick={() =>
                                  navigate("/newPackingList", { state: val })
                                }
                              >
                                <img
                                  src={Images?.editpencil}
                                  style={{ width: "50px", height: "25px" }}
                                  alt="Edit"
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            className="agent-view-user-table-data"
                            style={{ minWidth: "40px" }}
                          >
                            {i + 1}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.fileId || "-"}
                          </td>
                          <td
                            className="agent-view-user-table-data"
                            style={{ minWidth: "90px" }}
                          >
                            {val?.date || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.agentName || "-"}
                          </td>
                          <td
                            className="agent-view-user-table-data"
                            style={{ minWidth: "145px" }}
                          >
                            {val?.partyName || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.pkl || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.cbm || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.comm || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.loadvalue != null || val?.sumMTS != null
                              ? Number(val?.loadvalue ?? 0) +
                                Number(val?.sumMTS ?? 0)
                              : "-"}
                          </td>

                          <td className="agent-view-user-table-data">
                            {val?.received || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.paidAmount || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.balanceAmount || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.status || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            <div className="d-flex align-items-center justify-content-center gap-1">
                              <button
                                className="packinglist-view-btn"
                                onClick={() => {
                                  setDownloadPopUp(true);
                                  setPopUpData({
                                    ...popUpData,
                                    key: "pklreport",
                                    items: val,
                                  });
                                }}
                              >
                                PKL REPORT
                              </button>

                              <button
                                className="packinglist-view-btn"
                                onClick={() => {
                                  setDownloadPopUp(true);
                                  setPopUpData({
                                    ...popUpData,
                                    key: "acreport",
                                    items: val,
                                  });
                                }}
                              >
                                AC REPORT
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <h4 className="py-2 data-entry-sub-title">
                      No Records Found
                    </h4>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="7"
                      className="agent-view-user-table-data agent-view-totalAmt"
                    >
                      Total
                    </td>
                    <td className="agent-view-user-table-data agent-view-totalAmt">
                      {totalCbm}
                    </td>
                    <td className="agent-view-user-table-data agent-view-totalAmt">
                      {totalComm.toFixed(2)}
                    </td>
                    <td className="agent-view-user-table-data agent-view-totalAmt">
                      {totalLoad.toFixed(2)}
                    </td>
                    <td className="agent-view-user-table-data agent-view-totalAmt">
                      {totalReceived.toFixed(2)}
                    </td>
                    <td className="agent-view-user-table-data agent-view-totalAmt">
                      {totalPaid}
                    </td>
                    <td className="agent-view-user-table-data agent-view-totalAmt">
                      {totalBalance.toFixed(2)}
                    </td>
                    <td
                      colSpan="3"
                      className="agent-view-user-table-data agent-view-totalAmt"
                    ></td>
                  </tr>
                </tfoot>
              </table>
            </>
          )}
          {values?.role == "limitedAdmin" && (
            <>
              <table className="view-user-table">
                <thead>
                  <tr style={{ position: "sticky", top: "0px" }}>
                    <th className="agent-view-user-table-head --first-head">
                      EDIT
                    </th>
                    <th className="agent-view-user-table-head ">
                      CONTAINER NO
                    </th>
                    <th className="agent-view-user-table-head">PKL NO</th>
                    <th className="agent-view-user-table-head">LORRY NO</th>
                    <th className="agent-view-user-table-head">AGENT</th>
                    <th className="agent-view-user-table-head">DATE</th>
                    <th className="agent-view-user-table-head --last-head">
                      REPORT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td
                        colSpan="16"
                        style={{ textAlign: "center", fontWeight: "900" }}
                      >
                        L O A D I N G . . .
                      </td>
                    </tr>
                  ) : filteredData?.length !== 0 ? (
                    filteredData?.map((val) => {
                      const currentDate = new Date()
                        .toISOString()
                        .split("T")[0]; // Current date in YYYY-MM-DD format
                      const isEditable = val?.date === currentDate; // Check if dates match

                      return (
                        <tr key={val?.id} className="view-user-table-row">
                          {/* Edit Button */}
                          <td className="agent-view-user-table-data">
                            <div
                              onClick={() => {
                                if (isEditable) {
                                  navigate("/newPackingList", { state: val });
                                }
                              }}
                              style={{
                                cursor: isEditable ? "pointer" : "not-allowed",
                                opacity: isEditable ? 1 : 0.5,
                              }}
                              title={
                                isEditable
                                  ? "Click to edit"
                                  : "Editing disabled for this date"
                              }
                            >
                              <img
                                src={Images?.editpencil}
                                style={{ width: "50px", height: "25px" }}
                                alt="Edit"
                              />
                            </div>
                          </td>

                          {/* Other Data Fields */}
                          <td className="agent-view-user-table-data">
                            {val?.container || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.pkl || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.lorry || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.agentName || "-"}
                          </td>
                          <td className="agent-view-user-table-data">
                            {val?.date || "-"}
                          </td>

                          {/* PKL REPORT Button */}
                          <td className="agent-view-user-table-data">
                            <div className="d-flex align-items-center justify-content-center gap-1">
                              <button
                                className="packinglist-view-btn"
                                onClick={() => {
                                  if (isEditable) {
                                    setDownloadPopUp(true);
                                    setPopUpData({
                                      ...popUpData,
                                      key: "pklreport",
                                      items: val,
                                    });
                                  }
                                }}
                                disabled={!isEditable}
                                style={{
                                  cursor: isEditable
                                    ? "pointer"
                                    : "not-allowed",
                                  opacity: isEditable ? 1 : 0.5,
                                }}
                                title={
                                  isEditable
                                    ? "Click to download PKL Report"
                                    : "PKL Report download disabled for this date"
                                }
                              >
                                PKL REPORT
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="py-2 data-entry-sub-title" colSpan="16">
                        No Records Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            Are you sure to delete the user detail?
          </h6>
          <div className="d-flex justify-content-center gap-5 mb-3">
            <button
              className="user-delete-button"
              // onClick={() => HandlerDelete()}
              onClick={() => {
                handleDelete();
                setPopupOpen(false);
                setDeletePopUp(true);
              }}
            >
              OK
            </button>
            <button
              className="user-delete-button"
              onClick={() => {
                setPopupOpen(false);
              }}
            >
              NO
            </button>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={deletePopUp}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            PackingList has been deleted successfully
          </h6>
          <button
            className="user-delete-button mb-3"
            onClick={() => {
              // setDeletePopUp(false)
              // navigate("/agentManagement")
              setDeletePopUp(false);
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={downloadPopUp}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="py-4 d-flex justify-content-center align-items-center gap-2">
          {popUpData?.key === "pklreport" && (
            <>
              {Array.isArray(popUpData?.items?.newTeakRounds) &&
              popUpData?.items?.newTeakRounds?.length > 1 ? (
                <button
                  className="packinglist-download-button"
                  onClick={() => {
                    navigate("/pkldownload", { state: popUpData?.items });
                  }}
                >
                  Download Log
                </button>
              ) : Array.isArray(popUpData?.items?.newTeakSwan) &&
                popUpData?.items?.newTeakSwan?.length > 1 ? (
                <button
                  className="packinglist-download-button"
                  onClick={() => {
                    navigate("/swandownload", { state: popUpData?.items });
                  }}

                  // onClick={downloadSwanReport}
                  // onClick={generateSwanPdf}
                >
                  Download Swan
                </button>
              ) : (
                <button className="packinglist-download-button" disabled>
                  No Reports Available
                </button>
              )}
            </>
          )}

          {popUpData?.key == "acreport" && (
            <>
              <button
                className="packinglist-download-button"
                // onClick={downloadAcReport}
                onClick={() => {
                  navigate("/acdownload", { state: popUpData?.items });
                }}
              >
                Download
              </button>
            </>
          )}
          <button
            onClick={() => {
              setDownloadPopUp(false);
            }}
            className="packinglist-download-button"
          >
            Cancel
          </button>
          <button
            className="packinglist-download-button"
            onClick={() => {
              setDownloadPopUp(false);
              setSharePopUp(true);
            }}
          >
            Share
          </button>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={sharePopUp} onClose={toggleShare}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-4 pt-3 pb-4">
          <div
            style={{ width: "60px", height: "60px" }}
            onClick={handleWhatsAppClick}
          >
            <img
              src={Images?.whatsApp}
              style={{ width: "100%", height: "100%", cursor: "pointer" }}
            />
          </div>
          <div
            style={{ width: "60px", height: "60px" }}
            onClick={handleGmailClick}
          >
            <img
              src={Images?.gmail}
              style={{ width: "100%", height: "100%", cursor: "pointer" }}
            />
          </div>
        </div>
      </PopUpModal>
    </>
  );
}
