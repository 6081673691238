import React from "react";
import background from "./img/bg.png";
import Applogo from "./img/logo.png";
import emailIcon from "./img/email.png";
import passwordIcon from "./img/Password.png";
import hidepassword from "./img/hideps.png";
import showpassword from "./img/showps.png";
import backArrow from "./img/back.png";
import editIcon from "./img/pencil.png";
import searchIcon from "./img/search.png";
import downloadfile from "./img/downloadfile.png";
import whatsApp from "./img/whatsapp logo.png";
import gmail from "./img/gmail logo.png";
import listIcon from "./img/listIcon.png";
import shareIcon from "./img/shareIcon.png";
import viewIcon from "./img/viewIcon.png";
import besttimberIcon from "./img/besttimberlogo.jpg";
import backIcon from "./img/backIcon.png";

// import email from "./svg/email";
// import passwordLockIcon from "./svg/passwordLockIcon";
// import passwordVisible from "./svg/passwor, m,m,.m,, ,dVisible";
// import AddIcon from "./svg/AddIcon";
// import deleteIcon from "./svg/deleteIcon";
// import EditIcon from "./svg/EditIcon";
const Images = {
  bg: background,
  logo: Applogo,
  emails: emailIcon,
  password: passwordIcon,
  hidepasswords: hidepassword,
  showpasswords: showpassword,
  goback: backArrow,
  editpencil: editIcon,
  searchIcons: searchIcon,
  downloadfiles: downloadfile,
  whatsApp: whatsApp,
  gmail: gmail,
  listIcon: listIcon,
  shareIcon: shareIcon,
  viewIcon: viewIcon,
  besttimberIcon: besttimberIcon,
  backIcon: backIcon,
  // passwordVisibleIcon: passwordVisible,
  // adduserIcon: AddIcon,
  // deleteUserIcon: deleteIcon,
  // editPencilIcon: EditIcon,
};
export default Images;
